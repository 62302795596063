import React from "react";

const h2Css = {
    fontSize: "2.2rem",
    fontWeight: "500",
    color: "#133d5f",
};

export const ReporterModal = () => (
    <div>
        <div>
            <h2 style={h2Css} className="ng-binding">
                Member of the public
            </h2>
            <p>Anyone who isn’t one of the below.</p>
        </div>

        <div>
            <h2 style={h2Css} className="ng-binding">
                Scientist
            </h2>
            <p>A scientist reporting on something within their field of expertise.</p>
        </div>

        <div>
            <h2 style={h2Css} className="ng-binding">
                Primary industry professional
            </h2>
            <p>
                A farmer, forester, fisher, commercial grower, or someone who works supporting one of these and so can
                tell when something is unusual.
            </p>
        </div>

        <div>
            <h2 style={h2Css} className="ng-binding">
                Veterinarian
            </h2>
            <p>A trained vet or vet nurse reporting on something within their field of expertise.</p>
        </div>
        <div>
            <h2 style={h2Css} className="ng-binding">
            Veterinary Diagnostic Laboratory
            </h2>
            <p>A veterinary diagnostic laboratory.</p>
        </div>
        <div>
            <h2 style={h2Css} className="ng-binding">
                Biosecurity New Zealand border employee
            </h2>
            <p>Only for Biosecurity New Zealand employees reporting on something within their field of expertise.</p>
        </div>

        <div>
            <h2 style={h2Css} className="ng-binding">
                Transitional facility operator/employee
            </h2>
            <p>Only for staff of an approved Biosecurity New Zealand transitional facility.</p>
        </div>

        <div>
            <h2 style={h2Css} className="ng-binding">
                Ministry of Health employee
            </h2>
            <p>Only for Ministry of Health staff.</p>
        </div>
    </div>
);
