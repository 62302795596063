import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, withRouter, Redirect} from "react-router-dom";

import { clearPestInfo } from "../../actions/pest";
import { clearPersonInfo } from "../../actions/person";
import { toggleHeader, resetHeader } from "../../actions/form";

// Text for the redirects
const RedirectText = {
    'RedirectExoticUrgent': <p>Please call us now on <a href="tel:0800809966">0800 80 99 66</a> so we can investigate your issue immediately.
        <br />
        If you want to report something else, change your answers using the button below.
    </p>,

    'RedirectExoticUrgentWallabies': <p>Please call us now on <a href="tel:0800809966">0800 80 99 66</a> so we can investigate your issue immediately.
        <br />
        To report a wallaby sighting go to <a href="http://reportwallabies.nz/">reportwallabies.nz</a>
        <br />
        <br />
        If you want to report something else, change your answers using the button below.
    </p>,

    'RedirectGeneral':<p>If you want to report a pest or disease please either change your answers (button below) and choose
        the
        closest options, or call <a href="tel:0800809966">0800 80 99 66</a>.<br />
            If your enquiry is about something other than a pest or disease call <a href="tel:0800008333">0800 00 83 33</a>.
    </p>,

    'RedirectFisheries': <p>As you are wanting to talk about illegal fishing or poaching we need you to contact Fisheries New Zealand Illegal
        Fishing.<br/>
        You can phone them on <a href="tel:0800476224">0800 47 62 24</a>.
    </p>,

    'RedirectDoC':  <p>As you are wanting to talk about marine mammals we need you to contact the Department of
        Conservation.<br />
            You can phone them on <a href="tel:0800362468">0800 DOC HOT</a> (<a href="tel:0800362468">0800
            36 24
            68</a>).
    </p>,

    'RedirectCargo': <p>As you are wanting to talk about a recently imported item we need you to contact Biosecurity New Zealand Cargo Clearance.<br />
        You can phone them on <a href="tel:0800222018">0800 222 018</a>.
    </p>,

    'RedirectMoH': <p>As you are wanting to talk about mosquitoes we need you to contact the Ministry of Health.<br />
        You can phone them on <a href="tel:0800669943">0800 MOZZIE</a> (<a href="tel:0800669943">0800
        66 99
        43</a>).
    </p>,
};

class RedirectPage extends Component {
    getRedirectText = () => {
        let text = RedirectText[this.props.pest.redirect];

        if(typeof text === 'undefined') {
            text = <p>If you want to report a pest or disease please either change your answers (button below)
                and choose the closest options, or call <a href="tel:0800809966">0800 80 99 66</a>.<br />
                    If your enquiry is about something other than a pest or disease call <a href="tel:0800008333">0800 80 99 66 </a>.
            </p>;
        }

        return text;
    };

    reset = () => {
        this.props.resetHeader();
        this.props.clearPestInfo();
        this.props.clearPersonInfo();
    };

    render() {
        let reason = '';
        try {
            reason = this.props.pest.pestCategory.name.toLowerCase();
        } catch (e) {
            return <Redirect to="/" />
        }

        if(reason === 'something else') {
            return (
                <>
                    <div className="row">
                        <p>
                            If your enquiry is about something other than a pest or disease call 0800 00 83 33.<br/><br/>
                        </p>

                        <p>
                            If you want to report a pest or disease please either change your answers (button below) and choose the closest options, or call 08000 80 99 66.
                        </p>
                    </div>
                    <br />
                    <div className="row">
                        <Link to={"/pest/"}>
                            <div>
                                <button className="btn btn-primary" id="changeMyAnswers"
                                        name="changeMyAnswers" type="button"
                                        aria-label={"Go back and change my answers"}
                                        onClick={this.reset}>
                                    Change my answers
                                </button>
                            </div>
                        </Link>
                    </div>
                </>
            )
        }

        if(this.props.pest.redirect === 'RedirectFisheries' && !this.props.form.isFisheries) {
            this.props.toggleHeader();
        }

        return (
            <>
                <div className="row">
                    <p>
                        Thanks for contacting us about {(reason) ? reason : "something"}.<br/><br/>
                    </p>

                    {this.getRedirectText()}
                </div>
                <br />
                <div className="row">
                    <Link to={"/pest/"}>
                        <div>
                            <button className="btn btn-primary" id="changeMyAnswers"
                                    name="changeMyAnswers" type="button"
                                    aria-label={"Change my answers"}
                                    onClick={this.reset}>
                                Change my answers
                            </button>
                        </div>
                    </Link>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = { clearPestInfo, clearPersonInfo, toggleHeader, resetHeader };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RedirectPage));