import React from 'react';

const h2Css = {
    fontSize: "2.2rem",
    fontWeight: "500",
    color: "#133d5f"
}

export const PestCategoryModal = () => (
    <div>
        <div>
            <p>
                <h2 style={h2Css}>An urgent matter</h2>
                Anything you think might need attention now - another day or so might be too late.
            </p>
        </div>

        <div>
            <p><h2 style={h2Css}>A marine or fresh water issue</h2>
                Anything involving plants or creatures which live in rivers, streams, or the sea. For example an unusual
                fish, seaweed, or algae like didymo.</p>
        </div>

        <div>
            <p><h2 style={h2Css}>Plants, spiders, or insects</h2>
                Any plant or weed, or plant disease. Any sort of bug.</p>
        </div>

        <div>
            <p><h2 style={h2Css}>Animals or animal products</h2>
                Any land animal, or something made from or including any part of an animal (for
                example meat, bones, skins, feathers).</p>
        </div>

        <div>
            <p><h2 style={h2Css}>Something else</h2>
                Anything not related to the options above.</p>
        </div>
    </div>
);