import React, { Component } from "react";
import { connect } from "react-redux";

class Header extends Component {
    render() {
        let url = this.props.form.isFisheries ? "/fisheries-logo.png" : "/biosecurity-logo.png";

        let annoyingCSS = {
            logo: {
                //background: `url(${url}) 0 0 no-repeat transparent`,
                color: "transparent",
                WebkitTapHighlightColor: "transparent",
            },
        };

        return (
            <header id="page-head" role="banner">
                <div className="wrapper">
                    <h1>
                        <a href="https://www.mpi.govt.nz/" title="MPI Home Page" style={annoyingCSS.logo}>
                            {this.props.form.isFisheries ? (
                                <img
                                    alt="MPI Print Logo."
                                    src="/fisheries-logo.png"
                                    style={{ display: "block", height: "100%", margin: "0 auto" }}
                                />
                            ) : (
                                <img
                                    alt="MPI Print Logo."
                                    src="/biosecurity-logo.png"
                                    style={{ display: "block", height: "100%", margin: "0 auto" }}
                                />
                            )}
                        </a>
                    </h1>
                    <div id="page-head-utilities">
                        <div id="title-text">
                            <a href="/">Exotic Pest and Disease Online Notification</a>
                        </div>
                        <nav id="primary-nav" role="navigation">
                            <ul>
                                <li id="home-nav">
                                    <a href="/" title="Home" target="_self">
                                        <span className="fa fa-home" style={{ paddingRight: '1em'}} />
                                        <span className="home-text">Home</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(Header);
