import React, {Component} from "react";
import {connect} from "react-redux";
import {updatePersonInfo} from "../../../actions/person";
import {updateSubmissionErrors} from "../../../actions/form";

const regexList = {
    'reporterFirstName': /^[a-zA-Z\s\-'\.ŌĀĒĪŪāēīōū]*$/,
    'reporterSurname': /^[a-zA-Z\s\-'\.ŌĀĒĪŪāēīōū]*$/,
    'reporterPhone': /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
    'reporterSecondaryPhone': /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
    'reporterEmail': /^.+@.+\..+$/,
    'reporterStreet': /^[0-9a-zA-Z\s\/\-\.ŌĀĒĪŪāēīōū]*$/,
    'reporterSuburb': /^[0-9a-zA-Z\s\/\-'\.ŌĀĒĪŪāēīōū]*$/,
    'reporterTown': /^[0-9a-zA-Z\s\/\-'\.ŌĀĒĪŪāēīōū]*$/,
    'reporterPostcode': /^[0-9]*$/,
    'pestRegion': /^[a-zA-Z\s\-'\.ŌĀĒĪŪāēīōū]*$/,
};

class PersonInfo extends Component {
    constructor(props) {
        super(props);

        // Error messages
        this.state = {
            reporterFirstName: {
                pattern: false,
            },
            reporterSurname: {
                pattern: false,
            },
            reporterPhone: {
                pattern: false,
                minlength: false,
            },
            reporterSecondaryPhone: {
                pattern: false,
                minlength: false,
            },
            reporterEmail: {
                pattern: false,
            },

            // Person information
            person: {
                reporterFirstName: this.props.person.reporterFirstName,
                reporterSurname: this.props.person.reporterSurname,
                reporterPhone: this.props.person.reporterPhone,
                reporterSecondaryPhone: this.props.person.reporterSecondaryPhone,
                reporterEmail: this.props.person.reporterEmail,
            }
        };
    }

    componentDidMount() {
        this.validate();
    }

    validate(){
        const errPrefix="PERSON.";
        var currentErrors = (this.props.form.submissionErrors ||[]);
 
        if(currentErrors.length>0){
            currentErrors = currentErrors.filter(item => !(item.errFor.startsWith(errPrefix)));
        }
       
        if(this.state.reporterEmail.pattern || this.state.reporterEmail.minlength){
            currentErrors.unshift({errFor: errPrefix+"reporterEmail", errMessage: "Email is invalid"});
        }
        if(this.state.reporterEmail.required){
            currentErrors.unshift({errFor: errPrefix+"reporterEmail", errMessage: "Email is required"});
        }

        if(this.state.reporterPhone.pattern || this.state.reporterPhone.minlength){
            currentErrors.unshift({errFor: errPrefix+"reporterPhone", errMessage: "Phone number is invalid"});
        }
        if(this.state.reporterPhone.required){
            currentErrors.unshift({errFor: errPrefix+"reporterPhone", errMessage: "Phone is required"});
        }
        if(this.state.reporterSurname.pattern || this.state.reporterSurname.minlength){
            currentErrors.unshift({errFor: errPrefix+"reporterSurname", errMessage: "Surname is invalid"});
        }
        if(this.state.reporterSurname.required){
            currentErrors.unshift({errFor: errPrefix+"reporterSurname", errMessage: "Surname is required"});
        }

        if(this.state.reporterFirstName.pattern || this.state.reporterFirstName.minlength){
            currentErrors.unshift({errFor: errPrefix+"reporterFirstName", errMessage: "First name is invalid"});
        }
        if(this.state.reporterFirstName.required){
            currentErrors.unshift({errFor: errPrefix+"reporterFirstName", errMessage: "First name is required"});
        }

        this.props.updateSubmissionErrors(currentErrors);
    }

    handleChange = (e) => {
        // Value of the input
        let value = e.target.value.trim();
        // Name of the input
        let name = e.target.name;
        // Shortcut for ease
        let state = this.state;
        // Set all changes to the new state object
        let newState = {};

         // Get the person data
         let submissionErrors = this.props.form.submissionErrors;

        // Pattern is always going to be in the object
        if('pattern' in state[name]) {
            if(value.length > 0 && value.match(regexList[name]) === null) {
                // Set the pattern flag on the object to true
                newState = {...newState, [name]: {...newState[name], pattern: true}};
                state[name].pattern = true;
            } else {
                // Set the pattern flag to false
                newState = {...newState, [name]: {...newState[name], pattern: false}};
                state[name].pattern = false;
            }
        }

        if('required' in state[name]) {
            if(value === "") {
                // Set the required flag to true
                newState = {...newState, [name]: {...newState[name], required: true}};
                state[name].required = true;
            } else {
                // Set the required flag to false
                newState = {...newState, [name]: {...newState[name], required: false}};
                state[name].required = false;
            }
        }

        if('minlength' in state[name]) {
            // Only the phone number will have a length
            if(value.length > 0 && value.length < 9) {
                // Set the length flag to true
                newState = {...newState, [name]: {...newState[name], minlength: true}};
                state[name].minlength = true;
            } else {
                // Set the length flag to false
                newState = {...newState, [name]: {...newState[name], minlength: false}};
                state[name].minlength = false;
            }
        }

        // update the person object within newState
        newState.person = {...state.person, [name]: value};

        // Update the state with the new object
        this.setState(Object.assign({}, state, newState), () => {
            // Here, add to relevant redux functions
            this.props.updatePersonInfo(this.state.person);
        });

       this.validate();
        
    };

    render() {
        return (
            <div>
                <div className="form-group">
                    <label className="left" htmlFor="reporterFirstName">
                        First name
                    </label>
                    <input type="text" className="form-control" name="reporterFirstName"
                           id="reporterFirstName" maxLength="35"
                           onBlur={this.handleChange}
                    />
                    <div className="error message" role="alert">

                        {this.state.reporterFirstName.pattern && this.props.form.attemptsToSubmit>0 && <div>
                            Please only enter A-Z, a-z, -, space, ', or . characters for your first  name
                        </div>}
                    </div>
                </div>

                <div className="form-group">
                    <label className="left" htmlFor="reporterSurname">Surname</label>
                    <input type="text" className="form-control" name="reporterSurname"
                           id="reporterSurname" maxLength="35"
                           onBlur={this.handleChange}
                    />
                    <div className="error message" role="alert">
                        {this.state.reporterSurname.pattern && <div>
                            Please only enter A-Z, a-z, -, space, ', or . characters for your surname  name
                        </div>}
                    </div>
                </div>

                {/*PRIMARY PHONE BOX*/}
                <div className="form-group">
                    <label htmlFor="reporterPhone">
                        Phone
                    </label>
                    <input type="tel" className="form-control" name="reporterPhone"
                           id="reporterPhone" minLength={9} maxLength="13"
                           onBlur={this.handleChange}
                    />
                    <div className="error message" role="alert">
                        {this.state.reporterPhone.pattern && this.props.form.attemptsToSubmit>0 && <div>
                            Please only enter numbers for your phone number
                        </div>}

                        {this.state.reporterPhone.minlength && <div>Phone must be at least 9 digits</div>}
                    </div>
                </div>

                {/*SECONDARY PHONE BOX - removed Feb 2023*/}
                {/* <div className="form-group">
                    <label htmlFor="reporterSecondaryPhone">
                        Secondary phone including area code <span className="req-indicator">(optional)</span>
                    </label>
                    <input type="tel" className="form-control" name="reporterSecondaryPhone"
                           id="reporterSecondaryPhone" minLength={9} maxLength="13"
                           onBlur={this.handleChange}
                    />
                    <div className="error message" role="alert">
                        {this.state.reporterSecondaryPhone.pattern && <div>
                            Please only enter numbers for your phone number
                        </div>}

                        {this.state.reporterSecondaryPhone.minlength && <div>Phone must be at least 9 digits</div>}
                    </div>
                </div> */}

                <div className="form-group has-feedback">
                    <label htmlFor="reporterEmail">Email</label>
                    <div className="input-description">If you choose not to provide a phone number or email address we will be unable to contact you.
                    </div>
                    <input type="email" className="form-control" name="reporterEmail" id="reporterEmail" onBlur={this.handleChange}/>
                    <div className="error message" role="alert">
                        {this.state.reporterEmail.pattern && this.props.form.attemptsToSubmit>0 && <div>
                            Please enter a valid email address
                        </div>}
                    </div>
                    
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    updatePersonInfo, updateSubmissionErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonInfo);