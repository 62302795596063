export const updatePersonInfo = person => ({
    type: 'UPDATE_PERSON_INFO',
    person
});

export const setLocationFoundAt = locationFoundAt => ({
    type: 'SET_LOCATION',
    locationFoundAt

});

// Because of how the autocomplete applet thingy works, its very hard to clear the address if
export const setLocationFoundAtToggle = locationFoundAtToggle => ({
    type: 'SET_LOCATION_FOUND_AT_TOGGLE',
    locationFoundAtToggle
});

export const setReporterAddress = locationReporterAddress => ({
    type: 'SET_REPORTER_LOCATION',
    locationReporterAddress
});

export const setReporterRegion = locationReporterRegion => ({
    type: 'SET_REPORTER_REGION',
    locationReporterRegion
});

export const clearPersonInfo = () => ({
    type: 'CLEAR_PERSON_INFO',
});
