import React, {Component, useState} from "react";
import {connect} from "react-redux";
import Autosuggest from 'react-autosuggest';

// Azure
import {AuthenticationType} from 'azure-maps-control'

// Redux
import { updatePestInfo } from "../../../actions/pest";
import { setLocationFoundAtToggle } from "../../../actions/person";
import {toggleModal, updateSubmissionErrors} from "../../../actions/form";

// Components
import DatePicker from './components/DatePickerComponent';
import AddressSuggest from "./components/AddressSuggest";
import MyUploader from "./components/NewFileDropzone";

const option = {
    authOptions: {
        authType: AuthenticationType.subscriptionKey,
        subscriptionKey: 'lLNdaUfDdAF1nFOHbhG1b9dG_kXbnnHDUPJHnkcrt68'// Your subscription key
    },
};

const regexList = {
    'pestNumber': /^[0-9a-zA-Z\s\/\-()$,><=:\.]*$/,
    'pestDescription': '(?:</[^<]+>)|(?:<[^<]+/>)'
};

/* 
Regex for checking if string contains HTML:
a) /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i
b) /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/
c) (?:</[^<]+>)|(?:<[^<]+/>)

Test:
These do not contain HTML - match is null
<<Important Text>>
a < 3 && b > 3
This is less than <, this is greater than >.
<>
< >

These do contain HTML - match is not null
<a>Foo</a>

These do contain HTML for 'a' and 'b' - match is not null
"Foo &amp; bar"

These do contain HTML for regex 'a' and 'c'- match is not null
<img src="hello.jpg">

These do contain HTML for regex 'a'- match is not null
<a>
<script>
*/

class PestInfo extends Component {
    constructor(props) {
        super(props);
        const isLabUser = this.props.pest.reporterType.toLowerCase() === "veterinary diagnostic laboratory";

        // Error messages
        this.state = {
            pestNumber: {
                required: false
            },
            pestDescription: {
                required: false,
                pattern: false
            },
            //  information
            pest: {
                // Reporter data
                pestNumber: (isLabUser? "Samples":this.props.pest.pestNumber),
                pestDescription: this.props.pest.pestDescription,
            },

            includeLocation: false,
        };
       
        if(!this.props.pest.pestNumber){
            this.state.pestNumber.required=true;
        }
        if(!this.props.pest.pestDescription){
            this.state.pestDescription.required=true;
        }

        
    }

    componentDidMount() {
        this.props.setLocationFoundAtToggle(this.state.includeLocation);
        this.validate();
    }


    toggleMap = (showMap) => {
        this.setState({includeLocation: showMap});
        this.props.setLocationFoundAtToggle(showMap);
    };

    validate(){
        const errPrefix="PEST.";
        var currentErrors = (this.props.form.submissionErrors ||[]);
        const isLabUser = this.props.pest.reporterType.toLowerCase() === "veterinary diagnostic laboratory";

        if(currentErrors.length>0){
            currentErrors = currentErrors.filter(item => !(item.errFor.startsWith(errPrefix)));
        }

       if(!isLabUser && this.state.pestNumber.required){
           currentErrors.push({errFor: errPrefix+"pestNumber", errMessage: "A quantity is required"});
       }
       if(this.state.pestNumber.pattern){
           currentErrors.push({errFor: errPrefix+"pestNumber", errMessage: "Quantity is invalid"});
       }

       if(this.state.pestDescription.required){
           currentErrors.push({errFor: errPrefix+"pestDescription", errMessage: "A description is required"});
       }

       //https://www.tutorialspoint.com/how-to-check-if-a-string-is-html-or-not-using-javascript
       if(this.state.pestDescription.pattern){
           currentErrors.push({errFor: errPrefix+"pestDescription", errMessage: "Description is invalid"});
       }
       this.props.updateSubmissionErrors(currentErrors);
    };



    handleChange = (e) => {
        // Value of the input
        let value = e.target.value.trim();
        // Name of the input
        let name = e.target.name;
        let state = this.state;
        // Set all changes to the new state object
        let newState = {};       

        if('required' in state[name]) {
            if(value === "") {
                // Set the required flag to true
                newState = {...newState, [name]: {...newState[name], required: true}};
                state[name].required = true;
            } else {
                // Set the required flag to false
                newState = {...newState, [name]: {...newState[name], required: false}};
                state[name].required = false;
            }
        }

        if('pattern' in state[name]) {
            if(name=='pestDescription'){
                //Pattern match indicates the text has HTML elements in it
            if(value.length > 0 && value.match(regexList[name]) !== null) {
                // Set the pattern flag on the object to true
                newState = {...newState, [name]: {...newState[name], pattern: true}};
                state[name].pattern = true;
            } else {
                // Set the pattern flag to false
                newState = {...newState, [name]: {...newState[name], pattern: false}};
                state[name].pattern = false;
            }
        }
        else{
            if(value.length > 0 && !regexList[name].test(value)) {
                // Set the pattern flag on the object to true
                newState = {...newState, [name]: {...newState[name], pattern: true}};
                state[name].pattern = true;
            } else {
                // Set the pattern flag to false
                newState = {...newState, [name]: {...newState[name], pattern: false}};
                state[name].pattern = false;
            }
        }
        }


         // update the person object within newState
         newState.pest = {...state.pest, [name]: value};
 
         this.setState(Object.assign({}, state, newState), () => {
             this.props.updatePestInfo(this.state.pest);
         });
         this.validate();
       
    };

    render() {
        const isLabUser = this.props.pest.reporterType.toLowerCase() === "veterinary diagnostic laboratory";
        //this.validate();
        return (
            <div>
                <h2>Your report</h2>

                <div className="form-group">
                    <label htmlFor="pestDate">
                        When did you find this? <span className="req-indicator">(required)</span>
                    </label>

                    <br />

                    <DatePicker />
                </div>

                {!isLabUser  && (
                <>
                         <div className="form-group">
                    {/*TODO: add checks for this input*/}
                    <label htmlFor="pestNumber">How many (number) or how much (approximate size) did you find? <span
                        className="req-indicator">(required)</span></label>
                    <div className="input-description">For example: &quot;1&quot; or &quot;hundreds&quot; or &quot;about
                        the size of a dollar coin&quot;
                    </div>

                    <input type="text" className="form-control" name="pestNumber"
                           id="pestNumber" required maxLength="50"
                           aria-label={"Number of pests box"}
                           onBlur={this.handleChange}/>

                    <div className="error message" role="alert">
                        {this.state.pestNumber.required && this.props.form.attemptsToSubmit>0 && <div>Please tell us how many things you have found</div>}
                    </div>
                    <div className="error message" role="alert">

{this.state.pestNumber.pattern && this.props.form.attemptsToSubmit>0 && <div>
    Quantity contains text that is not valid
</div>}
</div>
                </div>

                <div className="form-group">
                    <label htmlFor="includeLocation">Did you find it at the address provided?</label>
                    <div>
                        <div className="btn-group">
                            <label className={`btn btn-default ${this.state.includeLocation ? "active" : ""}`}
                                   onClick={() => this.toggleMap(true)}>No</label>
                            <label className={`btn btn-default ${this.state.includeLocation ? "" : "active"}`}
                                   onClick={() => this.toggleMap(false)}>Yes</label>
                        </div>
                    </div>
                </div>
                <div className="form-group" style={{display: `${this.state.includeLocation ? "block" : "none"}`}}>
                    <label htmlFor="pac-input">Where did you discover this?</label>
                    <div>
                        <div className="input-description">
                            Search for a location
                        </div>

                        <AddressSuggest toggle={ this.state.includeLocation }/>

                        <br/>
                        {/*Map removed as per request in feature/68574_hide_redundant_map*/}
                        {/*<Map />*/}
                    </div>
                </div>
            </>  
            )}

                <div className="form-group">
                    <label htmlFor="upload">If you took photos, please upload them.</label>

                    <div className="input-description">
                        Please see <a href="" onClick={(e) => {
                        e.preventDefault();
                        this.props.toggleModal('PhotoGuide');
                    }} aria-label={"Open model for photography guide"}>A guide to taking pictures for reporting pests and diseases</a>.
                        <br />
                        For more details see the <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=0ehdLclfbL4" aria-label={"Open model for photography guide"}>
                            Guide to Photographing Biosecurity Suspects
                        </a> YouTube video from MPI.
                    </div>

                    <br />

                    {/*<FileDropzone />*/}
                    <MyUploader />
                </div>

                <div className="row">
                    <div className="col-md-4 upload-thumbnail" ng-repeat="f in $ctrl.files" style={{width:"140px"}}>
                        {/*<a style={{float:"right"}} ng-click="$ctrl.removeFile($index)" className="file-remove-icon" href="">*/}
                        {/*    <span className="glyphicon glyphicon-remove-circle" aria-hidden="true"></span>*/}
                        {/*</a>*/}
                        <img ngf-resize="{width: 100, height: 100, quality: 0.9}" />
                    </div>
                </div>

                <div className="form-group">
                    <label htmlFor="pestDescription">What did you discover? <span
                        className="req-indicator">(required)</span></label>
                    <div className="input-description">Describe what you've found (e.g. diseased plant, strange bug,
                        exotic
                        plant) and
                        where you found it (e.g. lettuce, inside a bag of rice, in the garden)
                    </div>
                    {/*TODO: update this with proper checks*/}
                    <textarea className="form-control" name="pestDescription" id="pestDescription"
                              rows="7" required maxLength="500"
                            onBlur={this.handleChange}
                    />

                    <div className="error message" role="alert">
                        {this.state.pestDescription.required && this.props.form.attemptsToSubmit>0 &&
                        <div>We need to know what you've found so we can assess it</div>}
                    </div>
                    <div className="error message" role="alert">

{this.state.pestDescription.pattern && this.props.form.attemptsToSubmit>0 && <div>
    The description contains text that is not valid
</div>}
</div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    setLocationFoundAtToggle,
    updatePestInfo,
    toggleModal, updateSubmissionErrors
};

export default connect(mapStateToProps, mapDispatchToProps)(PestInfo);
