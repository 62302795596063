import React, { Component } from "react";
import { connect } from "react-redux";

// Redux actions
import { toggleModal } from "../../actions/form";

// Modals
import { ReporterModal } from "./ModalContent/ReporterModal";
import { PestCategoryModal } from "./ModalContent/PestCategoryModal";
import PestSubCategoryModal from "./ModalContent/PestSubCategoryModal";
import { PhotoGuideModal } from "./ModalContent/PhotoGuideModal";
import { PrivacyModal } from "./ModalContent/PrivacyModal";

// Modal
const modalList = {
    ReporterTypeSection: <ReporterModal />,
    PestCategorySection: <PestCategoryModal />,
    PestSubCategorySection: <PestSubCategoryModal />,
    PhotoGuide: <PhotoGuideModal />,
    Privacy: <PrivacyModal />,
};

class Modals extends Component {
    render() {
        const isModalOpen = this.props.form.isModalOpen;

        if (isModalOpen) {
            return (
                <div
                    className="modal fade ng-scope ng-isolate-scope in"
                    role="dialog"
                    tabIndex="-1"
                    style={{ zIndex: "1050", display: "block" }}
                >
                    <div
                        className="modal-dialog modal-dialog-scrollable"
                        style={{ zIndex: "1050", display: "block", boxShadow: "0 0 0 9999px rgba(0, 0, 0, 0.5)" }}
                    >
                        <div className="modal-content">
                            <div>
                                <button
                                    type="button"
                                    className="close"
                                    aria-label="Close"
                                    onClick={() => this.props.toggleModal()}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body" id="modal-body">
                                {modalList[this.props.form.modalFor]}
                            </div>
                            <div className="modal-footer">
                                <button
                                    className="btn btn-primary"
                                    type="button"
                                    aria-label={"Back"}
                                    onClick={() => this.props.toggleModal()}
                                >
                                    Close
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return <div />;
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    toggleModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modals);
