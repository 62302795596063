import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";

// Redux
import {setReporterAddress, setReporterRegion} from "../../../../actions/person";
import Facilities from "./AddressInfo";

const KnownAddresses= (props) => {

      const [addressValue, setAddressValue] = useState("");
      const [suburbValue, setSuburbValue] = useState("");
      const [townValue, setTownValue] = useState("");
      const [postcodeValue, setPostcodeValue] = useState("");
      const [regionValue, setRegionValue] = useState("");
      const [isValidAddress, setIsValidAddress] = useState(false); 
      const [facilityValue, setFacility] = useState("");
      const [regionOptions, setRegionOptions] = useState([]);

      const dispatch = useDispatch();

      const getDataOrEmptyString = (str) => {
        if (str === undefined) return "";
        return str.length > 0 ? str : "";
    };

   const getFacilities = () => {
        let options = [];
        let count = 0;

        // Get the value from the questions
        options.push(<option />);

        // Get the value from the questions
        for(let [key, value] of Object.entries(Facilities)) {
            options.push(<option key={key} value={key} aria-label={value.name}>{value.name}</option>)
        }

        return options;
    };

    const clearAddress=()=>{
        setRegionValue("");
        setAddressValue("");
        setSuburbValue("");
        setTownValue("");
        setPostcodeValue("");
        setReporterRegion("");
        setIsValidAddress(false);
    };

    const onSelectFacility = (event) => {
        let value = event.target.value;
        clearAddress();
        setFacility(getDataOrEmptyString(value));        
        getRegionOptions(value);
    };

    const getRegionOptions = (facilityName) => {
      var facility = Facilities[facilityName]; 

        if('labRegion' in facility){
            var options=[];

            for(let [key, value] of Object.entries(facility.labRegion)) {
                options.push({'key': key, 'name': key});
            }
            setRegionOptions(options);

            if(options.length > 0){
                selectRegionFacility(facilityName, options[0].name);
            }
       }
    };

    const selectRegionFacility = (facilityName, regionName)=>{
        var facility = Facilities[facilityName]; 
        let loc = facility.labRegion[regionName];
        var streetAddress = facility.name + getDataOrEmptyString(", " + loc.address);

        setRegionValue(getDataOrEmptyString(loc.region));
        setAddressValue(getDataOrEmptyString(streetAddress));
        setSuburbValue(loc.suburb);
        setTownValue(getDataOrEmptyString(loc.city));
        setPostcodeValue(getDataOrEmptyString(loc.postcode));
        setReporterRegion(getDataOrEmptyString(loc.region));
        setIsValidAddress(true);

         //addresss information                                
        let locationReporterAddress = {
            facilityName: facility.name,
            streetName: loc.address,
            municipalitySubdivision: loc.suburb,
            municipality: loc.city,
            countrySecondarySubdivision: loc.region,
            countryTertiarySubdivision: loc.suburb,
            countrySubdivision: loc.countrySubdivision,
            postalCode: loc.postcode,
            countryCode: loc.countryCode,
            country: loc.country,
            countryCodeISO3: loc.countryCodeISO3,
            freeformAddress: streetAddress,
            localName: loc.suburb
        };
        dispatch(                                
            setReporterRegion(loc.region)
        );
        dispatch(
            setReporterAddress({ ...locationReporterAddress, ... locationReporterAddress.position })
        );
    };

   const onSelectRegion = (event) => { 
        let value = event.target.value;
        selectRegionFacility(facilityValue, value);
    };

  {
        return (
            <>
            <div className="form-group">
                <label htmlFor="addressType">Facility <span className="req-indicator">(required)</span></label>
                <select
                        className="form-control"
                        required=""
                        name="addressType"
                        id="addressType"
                        aria-label={"Facility"}
                        onChange={onSelectFacility}                   
                        defaultValue=""
                    >
                        {getFacilities()}
                    </select>                    
            </div>
            <div className="form-group">
                <label htmlFor="facilityRegion">Facility Region <span className="req-indicator">(required)</span></label>
                <select
                        className="form-control"
                        required=""
                        name="facilityRegion"
                        id="facilityRegion"
                        aria-label={"Region"}
                        onChange={onSelectRegion} >
                        {regionOptions? regionOptions.map((option) => (<option key={option.key} value={option.key} selected={option.name == regionOptions[0].name}>{option.name}</option>))
                            :<option value="" disabled>Choose Facility</option>}                
                    </select>                    
            </div>
             <div className="form-group">
             <label htmlFor="addressText" aria-label={"Address"}>
                 Address
             </label>
             <input type="text" className="form-control" name="addressText"
                           id="addressText" 
                           value={addressValue} readOnly
                    />
            </div>
            <div className="form-group">
                 <label htmlFor="addressSuburb" aria-label={"Suburb"}>
                     Suburb/RD
                 </label>
                 <input type="text" className="form-control" name="addressSuburb"
                           id="addressSuburb" 
                           value={suburbValue} readOnly
                    />
             </div>
             <div className="form-group">
                 <label htmlFor="addressTown" aria-label={"Town box"}>
                     Town/City
                 </label>
                 <input type="text" className="form-control" name="addressTown"
                           id="addressTown" 
                           value={townValue} readOnly
                    />
             </div>
             <div className="form-group">
                 <label htmlFor="addressPostcode" aria-label={"Postcode"}>
                     Postcode
                 </label>
                 <input type="text" className="form-control" name="addressTown"
                           id="addressTown" 
                           value={postcodeValue} readOnly
                    />
             </div>
             <div className="form-group">
                 <label htmlFor="addressRegion">
                     Region
                 </label> 
                 <input type="text" className="form-control" name="addressTown"
                           id="addressTown" 
                           value={regionValue} readOnly
                    />                
             </div>  
         </>    
        );
    }
}


const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = () => {
    return {
        setReporterAddress
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(KnownAddresses);