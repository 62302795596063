import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "./PestPage.css";
import ReporterTypeSection from "./Sections/ReporterTypeSection";
import PestCategorySection from "./Sections/PestCategorySection";
import PestSubCategorySection from "./Sections/PestSubCategorySection";
import ConditionalQuestionsSection from "./Sections/ConditionalQuestionsSection";

import { clearPestInfo } from "../../actions/pest";
import { clearPersonInfo } from "../../actions/person";
import { toggleHeader } from "../../actions/form";
import Modals from "../../components/Modals";

// This entire page could be broken down into several components
class PestPage extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        window.onpopstate = () => {
            // Reset the state if the back button is pressed
            this.props.clearPestInfo();
            this.props.clearPersonInfo();
        };
    }

    render() {
        // The pest category section needs to check if there is a redirect parameter or not

        // Redirect if we can redirect
        if (this.props.pest.redirect !== "") {
            const { match, location, history } = this.props;
            history.push("/pest/redirect/");

            // return <Redirect to={'/pest/redirect/'} />
            return <div />;
        }

        return (
            <div>
                <Modals />

                <form name="reportTypeForm">
                    {/*Outage Page Text*/}
                    {/* <div className="alert-warning" style={{ padding: "0.8em" }}>
                        <strong>
                            The online reporting form is currently unavailable. <br />
                            Please call us on the exotic pest and disease hotline – 0800 80 99 66.
                        </strong>
                    </div> */}
                    {/*Main Home Page Text*/}
                    <div className="alert-warning" style={{ padding: "0.8em" }}>
                       <strong>
                           Do not use this form to report:
                          <ul>
                              <li>urgent issues requiring immediate action</li>
                            <li>animal diseases</li>
                           <li>mass mortality events involving dozens of individual organisms</li>
                          </ul>
                          For these situations please call us on 0800 80 99 66.
                       </strong>
                   </div>

                    <p>
                       Complete this form to report any suspect pests or diseases you find. We’ll make sure your report
                        gets to the right people.
                    </p>
                    {/*<p>*/}
                    {/*    <strong>Are you reporting a mass mortality or an animal disease?<br />*/}
                    {/*        Stop using this form and call us on <a href="tel:0800809966">0800 80 99 66</a>.</strong>*/}
                    {/*</p>*/}

                    <hr role="presentation" />

                    <ReporterTypeSection />

                    <PestCategorySection />

                    <PestSubCategorySection />

                    <ConditionalQuestionsSection />
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps, { clearPestInfo, clearPersonInfo, toggleHeader })(withRouter(PestPage));
