import React, {Component} from "react";
import {connect} from "react-redux";

// Redux
import {updatePestCategory, updateRedirect} from "../../../actions/pest";
import {toggleModal} from "../../../actions/form";

// We need static questions
import Questions from '../Questions';

class PestCategorySection extends Component {

    getQuestions = () => {
        let options = [];
        let count = 0;

        // Get the questions from the Question Object
        let pestCategory = Questions[this.props.pest.reporterType].pestCategory;

        // Get the value from the questions
        for(let [key, value] of Object.entries(pestCategory)) {
            if(this.count === 0) {
                options.push(<option selected disabled hidden key={key} value={key} aria-label={value.name}>{value.name}</option>)
            } else {
                options.push(<option key={key} value={key} aria-label={value.name}>{value.name}</option>)
            }
            count++;
        }

        if(pestCategory && Object.keys(pestCategory).length==1){
            var categoryKey = Object.keys(pestCategory)[0];
            this.setSelection(categoryKey);
        }
        return options;

    };

    setSelection = (category) => {
        let pestCategory = Questions[this.props.pest.reporterType].pestCategory[category];

        if('redirectTo' in pestCategory) {
            this.props.updateRedirect(pestCategory.redirectTo);
        }

        this.props.updatePestCategory(pestCategory);          
    };

    handleChange = (event) => {
        // Update the redux state to have a reporter typeread
        //this.props.updatePestReporter(event.target.value);
        let value = event.target.value;

        this.setSelection(value);
    };

    render() {
        const reporterType = this.props.pest.reporterType;

        if (reporterType !== "") {
            let key = this.props.pest.pestCategory.value || "";
            return (
                <div className="form-group">
                    <label htmlFor="pestCategory">wanting to make a report about</label>
                    <div className="input-group">
                        <select className="form-control" required=""
                                name="pestCategory" id="pestCategory"
                                value={key}
                                onChange={this.handleChange}>
                            {this.getQuestions()}
                        </select>
                        <div className="input-group-addon" onClick={(e) => {
                            e.preventDefault();
                            this.props.toggleModal('PestCategorySection');
                        }}>
                            <a href="" aria-haspopup="true">
                                <span className="glyphicon glyphicon-question-sign" style={{fontSize: "120%"}} />
                            </a>
                        </div>
                    </div>
                </div>
            );
        }

        return <div />;
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = { updatePestCategory, updateRedirect, toggleModal };

export default connect(mapStateToProps, mapDispatchToProps)(PestCategorySection)
