// Describe the initial state of the pest
const initialState = {
    // Reporter data
    reporterType: "",
    pestCategory: {},
    pestSubCategory: {},
    pestDate: new Date().toLocaleString(),

    notificationScreener: "",

    // Detail form information
    pestDescription: "",
    pestNumber: "",

    // Even though this should be part of the form, this is also required for the pest
    condQuestions: {
        isRecentlyImportedGood: false,
        isAnimalMortality: false,
        isBeeDisease: false,
        isIdentifyingBeeWasp: false,
    },

    // Files to send to the server
    files: [],

    // This stuff won't be POSTed
    redirect: "",
    isCondQuestion: false,
};

const maybeNewState = {
    pest: {
        category: "",
        subCategory: "",
        date: new Date().toLocaleString(),
        description: "",
        number: "",
    },

    condQuestions: {
        isRecentlyImportedGood: false,
        isAnimalMortality: false,
        isBeeDisease: false,
        isIdentifyingBeeWasp: false,
    },

    files: [],
    notificationScreener: "",
};


// Remove the unnecessary subobjects
const objectWithoutKey = (object, key) => {
    const {[key]: _, ...otherKeys} = object;
    return otherKeys;
};

// Helpers for the reducers - thunks
const removeSubCategory = obj => objectWithoutKey(obj, 'subcategory');
//const removeCondQuestion = obj => objectWithoutKey(obj, 'condQuestion');

const pest = (state = initialState, action) => {
    // Set the state
    switch (action.type) {
        case 'UPDATE_PEST_INFO':
            return {
                ...state,
                ...action.pest,
            };

        case 'CLEAR_PEST_INFO':
            return {
                ...initialState
            };

        case 'UPDATE_PEST_REPORTER':
            return {
                ...initialState,
                reporterType: action.reporterType,
                pestCategory: {},
                pestSubCategory: {}
            };

        case 'UPDATE_PEST_CATEGORY':
            return {
                ...state,
                // Gotta remove the subCategory
                pestCategory: removeSubCategory(action.pestCategory)
            };

        case 'UPDATE_PEST_SUB_CATEGORY':
            let part = null;
            // One pest category counts as an imported item. Needs to make common.
            if (action.pestSubCategory.value === "Recently Imported Items") {
                part = { condQuestions: {
                        ...initialState.condQuestions,
                        isRecentlyImportedGood: true
                    }};
            }
            return {
                ...state,
                ...part,
                // Gotta remove the conditional questions
                pestSubCategory: action.pestSubCategory
            };

        case 'UPDATE_NOTIFICATION_SCREENER':
            return {
                ...state,
                notificationScreener: action.notificationScreener
            };

        case 'UPDATE_PEST_DATE':
            return {
                ...state,
                pestDate: action.pestDate
            };

        case 'UPDATE_PEST_FILES':
            return {
                ...state,
                files: [...state.files, action.files]
            };
            case 'RESET_PEST_FILES':
                return {
                    ...state,
                    files: action.files
                };
        case 'UPDATE_REDIRECT':
            return {
                ...state,
                redirect: action.redirect
            };

        case 'UPDATE_COND_QUESTIONS':
            // condQuestions from the action object is the key for the condQuestions pest object
            return {
                ...state,
                isCondQuestion: action.payload.truthiness,
                condQuestions: {
                    ...initialState.condQuestions,
                    [action.payload.condQuestion]: action.payload.truthiness
                },
            };

        case 'UPDATE_IS_COND_QUESTIONS':
            return {
                ...state,
                isCondQuestion: action.isCondQuestion
            };

        // Default needed for default state
        default:
            return state;
    }
};

export default pest;