import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";
import {
    updatePestSubCategory,
    updateRedirect,
    updateCondQuestions,
    updateIsCondQuestion,
    updateNotificationScreener,
} from "../../../actions/pest";
import {toggleModal} from "../../../actions/form";

// We need static questions
import Questions from '../Questions';

class PestSubCategorySection extends Component {

    getQuestions = () => {
        let options = [];

        // Get the questions from the Question Object
        let reporterType = this.props.pest.reporterType;
        let pestCategory = this.props.pest.pestCategory.value;
        let subCategory = Questions[reporterType].pestCategory[pestCategory].subcategory;

        options.push(<option />);

        // Get the value from the questions
        if(subCategory){
            for(let [key, value] of Object.entries(subCategory)) {
                options.push(<option key={key} value={key} aria-label={value.name}>{value.name}</option>)
            }
        }
        else{
            this.setSelection("");
        }

        return options;
    };

    setSelection = (category) => {
        let pestCategory = this.props.pest.pestCategory.value;

        if(category && category.length>0){
            let pestSubCategory = Questions[this.props.pest.reporterType].pestCategory[pestCategory].subcategory[category];

            if(typeof pestSubCategory === 'object') {
                // Handle the redirect to
                if('redirectTo' in pestSubCategory) {
                    this.props.updateRedirect(pestSubCategory.redirectTo);
                } else
                    // Handle the condQuestions
                if('condQuestion' in pestSubCategory) {
                    let condQuestion = pestSubCategory.condQuestion.name;
                    let truthiness = true;

                    // Update state
                    this.props.updateIsCondQuestion(true);
                    this.props.updateCondQuestions({condQuestion, truthiness});
                    this.props.updateNotificationScreener(pestSubCategory.notificationScreener);
                    this.props.updatePestSubCategory(pestSubCategory);
                }
                else
                {
                    const { history } = this.props;

                    this.props.updateNotificationScreener(pestSubCategory.notificationScreener);
                    this.props.updatePestSubCategory(pestSubCategory);

                    history.push('/pest/details/');
                }
            }
        }
        else{
            const { history } = this.props;
            this.props.updatePestSubCategory("");

            history.push('/pest/details/');
        }
    };

    handleChange = (event) => {
        let value = event.target.value;
        this.setSelection(value);
    };

    render() {
        const pestCategory = this.props.pest.pestCategory;

        if (Object.keys(pestCategory).length !== 0) {
            return (
                <div className="form-group">
                    <label htmlFor="pestSubCategory">Specifically I need to talk to you about</label>
                    <div className="input-group">
                        <select className="form-control" required=""
                                name="pestSubCategory" id="pestSubCategory"
                                onChange={this.handleChange}>
                            {this.getQuestions()}
                        </select>
                        <div className="input-group-addon" onClick={(e) => {
                            e.preventDefault();
                            this.props.toggleModal('PestSubCategorySection');
                        }}>
                            <a href="" aria-haspopup="true">
                                <span className="glyphicon glyphicon-question-sign" style={{fontSize: "120%"}} />
                            </a>
                        </div>
                    </div>
                </div>
            );
        }

        return <div />;
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    updatePestSubCategory,
    updateRedirect,
    updateCondQuestions,
    updateIsCondQuestion,
    updateNotificationScreener,
    toggleModal
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PestSubCategorySection))
