import React, {useState} from "react";
import DatePickerComponent from "react-datepicker";
import {connect, useSelector, useDispatch} from "react-redux";

// Redux
import {updatePestDate} from "../../../../actions/pest";
import {updateSubmissionErrors} from "../../../../actions/form";

// Styles
import "react-datepicker/dist/react-datepicker.css";
import "./DatePickerComponent.css";

// Check the date is valid?


// New Component for the date picker
const DatePicker = (props) => {
    const [startDate, setStartDate] = useState(new Date());
    //const counter = useSelector(state => state);
    const dispatch = useDispatch();

    return (
        <DatePickerComponent selected={startDate}
                             showMonthDropdown
                             //dateFormat="yyyy-MM-dd"
                             dateFormat="dd-MM-yyyy"
                             maxDate={new Date()} // Exclude days from tomorrow
                             onChange={date => {
                                 // Set the state
                                 const errPrefix="DATE.";
                                 var currentErrors = (props.form.submissionErrors ||[]);
                             
                                 if(currentErrors.length>0){
                                     currentErrors = currentErrors.filter(item => !(item.errFor.startsWith(errPrefix)));
                                 }      

                                 const checkDate = (date || "");
                                 let parsedDate = new Date(checkDate);

                                 if(!date || date.length==0 || isNaN(parsedDate.getTime())){
                                     currentErrors.push({errFor: errPrefix, errMessage: "Issue with date field"});    
                                 }

                                 dispatch(props.updateSubmissionErrors(currentErrors));
                                 setStartDate(date);

                                 // Set the redux state
                                 dispatch(props.updatePestDate(date));
                             }} />
    );
};

// Add redux stuff here
const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = () => {
    return {
        updatePestDate,updateSubmissionErrors
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DatePicker);