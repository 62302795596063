import React from 'react';

export const PhotoGuideModal = () => (
    <div>
        <b>All Images:</b>
        <p>
            Take photos in good light: try with and without a flash and choose the best one.
        </p>
        <p>
            Look for good focus and avoid glare and shiny surfaces, like water or glossy leaves.
        </p>
        <p>
            Put a coin, pen, ruler or finger next to it in the photo for something small so we can tell size.
        </p>
        <p>
            If your photo is too big, try cropping the image before uploading it.
        </p>
        <hr role='presentation' />
        <p>
            <b>All land animals:</b> take a few photos, especially of the top side, but underside too if possible.
        </p>
        <p>
            <b>Live insect:</b> if it will not sit still, put it in a clear jar and chill it in the fridge for a few minutes.
        </p>
        <p>
            <b>Plant disease:</b> take a close-up image of the diseased area, but also take a photo of an entire leaf (and flower or fruit if possible) and one of the entire plant.
        </p>
        <p>
            <b>Fish:</b> take a few photos from the side, including fins.
        </p>
    </div>
)