function pestCategoryBaseObject() {
    // Note:
    //  In this data structure the strings used as keys are there to make references to the data structure more meaniningful e.g. refData['Urgent'][...]
    //  The keys are not used in the UI itself.
    return {
        "": {
            name: "",
            value: "",
            description: "",
            redirect: false,
        },
        Urgent: {
            name: "an urgent matter",
            value: "Urgent",
            description: "Anything you think might need attention now - another day or so might be too late.",
            redirect: true,
            redirectTo: "RedirectExoticUrgent",
        },
        "Biosecurity Eradication Programme": {
            name: "a biosecurity eradication programme",
            value: "Biosecurity Eradication Programme",
            description:
                "Something Biosecurity New Zealand has already discovered and is working on eradicating, either short-term like a fruit fly or long-term like Kauri dieback.",
            redirect: true,
            redirectTo: "RedirectExoticUrgent",
        },
        "Marine or Fresh Water Issue": {
            name: "a marine or fresh water issue",
            value: "Marine or Fresh Water Issue",
            description:
                "Anything involving plants or creatures which live in rivers, streams, or the sea. For example an unusual fish, seaweed, or algae like didymo.",
            redirect: false,
            subcategory: {
                "Fresh Water, Plants or Fresh Water Algae": {
                    name: "fresh water plants or fresh water algae",
                    value: "Fresh Water, Plants or Fresh Water Algae",
                    description: "Any plant or algae (for example didymo) living in fresh water.",
                    notificationScreener: "Plants & Environment",
                    redirect: false,
                },
                Aquaculture: {
                    name: "aquaculture",
                    value: "Aquaculture",
                    description: "Anything farmed in fresh or salt water.",
                    notificationScreener: "Aquatic",
                    redirect: false,
                },
                "Fresh water fish": {
                    name: "fresh water fish or crustaceans",
                    value: "Fresh Water Fish or Custaceans",
                    description: "Any animal living in fresh water.",
                    notificationScreener: "Aquatic",
                    redirect: false,
                },
                "Marine fish": {
                    name: "marine fish, animals, or seafood",
                    value: "Marine Fish, Animals or Seafood",
                    description: "Any animal living in salt water.",
                    notificationScreener: "Aquatic",
                    redirect: false,
                },
                "Seaweed & Algae": {
                    name: "seaweed or marine algae",
                    value: "Seaweed or Marine Algae",
                    description: "Seaweeds or other salt water plants or algae.",
                    notificationScreener: "Aquatic",
                    redirect: false,
                },
                "Excess fishing or poaching": {
                    name: "illegal fishing or poaching",
                    value: "Ilegal Fishing or Poaching",
                    description: "Any fishing activity which might be illegal or is suspicious.",
                    redirect: true,
                    redirectTo: "RedirectFisheries",
                },
                "Whales, seals, or sea mammals": {
                    name: "whales, seals, or marine mammals",
                    value: "Whales, Seals, or Marine Mammals",
                    description: "For example all whales, dolphins, seals, sea lions.",
                    redirect: true,
                    redirectTo: "RedirectDoC",
                },
            },
        },
        "Plants Spiders or Insects": {
            name: "plants, spiders, or insects",
            value: "Plants Spiders or Insects",
            description: "Any plant or weed, or plant disease. Any sort of insect.",
            redirect: false,
            subcategory: {
                "Plant Disease": {
                    name: "a plant disease",
                    value: "Plant Disease",
                    description: "Any disease affecting a plant.",
                    notificationScreener: "PHEL",
                    redirect: false,
                },
                Plants: {
                    name: "land plants, fresh water plants or algae",
                    value: "Land Plants, Fresh Water or Algae",
                    description: "Any plant or algae (for example didymo) not growing in the sea.",
                    redirect: false
                    // condQuestion: {
                    //     name: "isRecentlyImportedGood",
                    //     response: [
                    //         {
                    //             value: "true",
                    //             notificationScreener: "Plants & Environment",
                    //             redirect: false,
                    //         },
                    //         {
                    //             value: "false",
                    //             notificationScreener: "PHEL",
                    //             redirect: false,
                    //         },
                    //     ],
                    // },
                },
                "Spiders & Invertebrates": {
                    name: "insects, spiders, or invertebrates",
                    value: "Insects, Spiders or Invertebrates",
                    description: "Any sort of insect, for example insects or worm, found on land.",
                    notificationScreener: "Aquatic",
                    redirect: false,
                    condQuestion: {
                        name: "isRecentlyImportedGood",
                        response: [
                            {
                                value: "true",
                                notificationScreener: "Plants & Environment",
                                redirect: false,
                            },
                            {
                                value: "false",
                                notificationScreener: "PHEL",
                                redirect: false,
                            },
                        ],
                    },
                },
                "Seaweed & Algae": {
                    name: "seaweed or marine algae",
                    value: "Seaweed or Marine Algae",
                    description: "Seaweeds or other salt water plants or algae.",
                    notificationScreener: "Aquatic",
                    redirect: false,
                },
                "Imported Items": {
                    name: "a recently imported item",
                    value: "Recently Imported Items",
                    description:
                        "Something recently imported which might be hiding a biosecurity risk, for example something just arrived from overseas which shows signs of insect damage.",
                    notificationScreener: "Plants & Environment",
                    redirect: false,
                },
                "Animal Parasites": {
                    name: "ticks, fleas, or animal parasites",
                    value: "Ticks, Fleas or Animal Parasites",
                    description: "Any insect or worm which appears to be living on an animal.",
                    notificationScreener: null,
                    redirect: true,
                    redirectTo: "RedirectExoticUrgent",
                },
                Bees: {
                    name: "bees",
                    value: "Bees",
                    description: "Honey bees or any type of wasp.",
                    notificationScreener: null,
                    redirect: true,
                    redirectTo: "RedirectExoticUrgent",
                    // condQuestion: {
                    //     name: "isIdentifyingBeeWasp",
                    //     response: [
                    //         {
                    //             value: "true",
                    //             notificationScreener: "PHEL",
                    //             redirect: false,
                    //         },
                    //         {
                    //             value: "false",
                    //             notificationScreener: null,
                    //             redirect: true,
                    //             redirectTo: "RedirectExoticUrgent",
                    //         },
                    //     ],
                    // },
                },
                Mosquitoes: {
                    name: "mosquitoes",
                    value: "Mosquitoes",
                    description: "Any report of a mosquito, whether it is common to New Zealand or not.",
                    notificationScreener: null,
                    redirect: true,
                    redirectTo: "RedirectMoH",
                }
            },
        },
        "Animals or Animal Products": {
            name: "animals or animal products",
            value: "Animals or Animal Products",
            description:
                "Any land animal bigger than a insect, or something made from or including any part of an animal (for example meat, bones, skins, feathers).",
            redirect: false,
            subcategory: {
                "Farm Animals": {
                    name: "farm animals",
                    value: "Farm Animals",
                    description: "An animal being farmed commercially.",
                    notificationScreener: null,
                    redirect: true,
                    redirectTo: "RedirectExoticUrgent",
                },
                "Exotic Animals": {
                    name: "exotic animals",
                    value: "Exotic Animals",
                    description: "Any animal which isn’t a pet or a farmed animal.",
                    notificationScreener: null,
                    redirect: true,
                    redirectTo: "RedirectExoticUrgentWallabies",
                },
                "Domestic Animals": {
                    name: "domestic animals",
                    value: "Domestic Animals",
                    description:
                        "Pets or companion animals which are not being commercially farmed, for example cats, dogs, birds, and horses.",
                    notificationScreener: null,
                    redirect: true,
                    redirectTo: "RedirectExoticUrgent",
                },
                "Ticks, Fleas or Animal Parasites": {
                    name: "ticks, fleas, or animal parasites",
                    value: "Ticks, Fleas or Animal Parasites",
                    description: "Any insect or worm which appears to be living on an animal.",
                    redirect: true,
                    redirectTo: "RedirectExoticUrgent",
                },
                "Imported Items": {
                    name: "a recently imported item",
                    value: "Recently Imported Items",
                    description:
                        "Something recently imported which might be hiding a biosecurity risk, for example something just arrived from overseas which shows signs of insect damage.",
                    redirect: true,
                    redirectTo: "RedirectExoticUrgent",
                },
                Bees: {
                    name: "bees",
                    value: "Bees",
                    description: "Honey bees, or diseases affecting bees.",
                    notificationScreener: null,
                    redirect: true,
                    redirectTo: "RedirectExoticUrgent",
                    // condQuestion: {
                    //     name: "isIdentifyingBeeWasp",
                    //     response: [
                    //         {
                    //             value: "true",
                    //             notificationScreener: "PHEL",
                    //             redirect: false,
                    //         },
                    //         {
                    //             value: "false",
                    //             notificationScreener: null,
                    //             redirect: true,
                    //             redirectTo: "RedirectExoticUrgent",
                    //         },
                    //     ],
                    // },
                },
            },
        },
        Other: {
            name: "something else",
            value: "Other",
            description: "Anything not related to the options above.",
            redirect: true,
            redirectTo: "RedirectGeneral",
        },
    };
}

function animalOnlyBaseObject() {
    return {
        "Animals or Animal Products": {
            name: "animals or animal products",
            value: "Animals or Animal Products",
            description:
                "Any land animal bigger than a insect, or something made from or including any part of an animal (for example meat, bones, skins, feathers).",
            redirect: false
        }
    };
}

function refDataObject() {
    var refData = {
        reporterType: {
            "": {
                name: "",
                value: "",
                pestCategory: copyJsonObject(pestCategoryBaseObject()),
            },
            Public: {
                name: "member of the public",
                value: "Public",
                description: "Anyone who isn’t one of the below.",
                pestCategory: copyJsonObject(pestCategoryBaseObject()),
            },
            Scientist: {
                name: "scientist",
                value: "Scientist",
                description: "A scientist reporting on something within their field of expertise.",
                pestCategory: copyJsonObject(pestCategoryBaseObject()),
            },
            "Primary Industry Professional": {
                name: "primary industry professional",
                value: "Primary Industry Professional",
                description:
                    "A farmer, forester, fisher, commercial grower, or someone who works supporting one of these and so can tell when something is unusual.",
                pestCategory: copyJsonObject(pestCategoryBaseObject()),
            },
            Veterinarian: {
                name: "veterinarian",
                value: "Veterinarian",
                description: "A trained vet or vet nurse reporting on something within their field of expertise.",
                pestCategory: copyJsonObject(pestCategoryBaseObject()),
            },
            "Veterinary Diagnostic Laboratory": {
                name: "Veterinary Diagnostic Laboratory",
                value: "Veterinary Diagnostic Laboratory",
                description: "A veterinary diagnostic laboratory.",
                pestCategory: copyJsonObject(animalOnlyBaseObject()),
            },
            "MPI Border Group": {
                name: "Biosecurity New Zealand border employee",
                value: "Biosecurity New Zealand Border Employee",
                description:
                    "Only for Biosecurity New Zealand border officers reporting on something within their field of expertise.",
                pestCategory: copyJsonObject(pestCategoryBaseObject()),
            },
            "Transitional Facility": {
                name: "transitional facility operator/employee",
                value: "Transitional Facility",
                description: "Only for staff of an approved Biosecurity New Zealand transitional facility.",
                pestCategory: copyJsonObject(pestCategoryBaseObject()),
            }
        },
    };

    // Update the base reporterType data structure with exceptions:
    // *** NOTE: If implementing exceptions always copy the JSON objects via copyJsonObject,
    // ***   otherwise two different nodes in the data structure will refer to the same object,
    // ***   which is probably not a good idea.
    // *** This code should probably be refactored so that each node has an 'action' property that contains redirect/redirectTo/NotificationScreener etc.
    // ***   This would allow easy overriding of just the action rather than having to reiterate the name/value/description.

    // Exceptions for reporterType: Transitional Facility
    refData.reporterType["Transitional Facility"].pestCategory["Plants Spiders or Insects"].subcategory["Imported Items"] = {
        name: "a recently imported item",
        value: "Recently Imported Items",
        description:
            "Something recently imported which might be hiding a Biosecurity New Zealand risk, for example something just arrived from overseas which shows signs of insect damage.",
        redirect: true,
        redirectTo: "RedirectCargo",
    };


    refData.reporterType["Transitional Facility"].pestCategory["Plants Spiders or Insects"].subcategory["Spiders & Invertebrates"] = {
        name: "insects, spiders, or invertebrates",
        value: "Insects, Spiders or Invertebrates",
        description: "Any sort of insect, for example insects or worm, found on land.",
        notificationScreener: "Aquatic",
        redirect: false
        // condQuestion: {
        //     name: "isRecentlyImportedGood",
        //     response: [
        //         {
        //             value: "true",
        //             notificationScreener: "Plants & Environment",
        //             redirect: true,
        //             redirectTo: "RedirectCargo",
        //         },
        //         {
        //             value: "false",
        //             notificationScreener: "PHEL",
        //             redirect: false,
        //         },
        //     ],
        // },
    };


    // Exceptions for reporterTypes: Primary Industry Professional, Veterinarian, Scientist
    // Override the 'Plants' and 'Spiders & Invertebrates' subcategories.
    var plants = getPlants();
    plants.subcategory["Plants"] = {
        name: "land plants, fresh water plants or algae",
        value: "Land Plants, Fresh Water Plants, or Algae",
        description: "Any plant or algae (for example didymo) not growing in the sea.",
        notificationScreener: "Plants & Environment",
        redirect: false,
    };

    // November 2023 - requested to put back recently imported question (//CB - removed change as not required)
    plants.subcategory["Spiders & Invertebrates"] = {
        name: "insects, spiders, or invertebrates",
        value: "Insect, Spiders or Invertebrates",
        description: "Any sort of insect, for example insects or worm, found on land.",
        notificationScreener: "Plants & Environment",
        redirect: false
        // condQuestion: {
        //     name: "isRecentlyImportedGood",
        //     response: [
        //         {
        //             value: "true",
        //             notificationScreener: "Plants & Environment",
        //             redirect: false,
        //         },
        //         {
        //             value: "false",
        //             notificationScreener: "Plants & EnvironmentHEL",
        //             redirect: false,
        //         },
        //     ],
        // }
    };

    refData.reporterType["Primary Industry Professional"].pestCategory["Plants Spiders or Insects"] =
        copyJsonObject(plants);
    refData.reporterType["Veterinarian"].pestCategory["Plants Spiders or Insects"] = copyJsonObject(plants);
    refData.reporterType["Scientist"].pestCategory["Plants Spiders or Insects"] = copyJsonObject(plants);

    // Exceptions for reporterTypes: Primary Industry Professional, Scientist
    // Override the 'Plant Disease' subcategory.
    var plantDisease = {
        name: "a plant disease",
        value: "Plant Disease",
        description: "Any disease affecting a plant.",
        notificationScreener: "Plants & Environment",
        redirect: false,
    };

    refData.reporterType["Primary Industry Professional"].pestCategory["Plants Spiders or Insects"].subcategory[
        "Plant Disease"
    ] = copyJsonObject(plantDisease);
    refData.reporterType["Scientist"].pestCategory["Plants Spiders or Insects"].subcategory["Plant Disease"] =
        copyJsonObject(plantDisease);

    return refData;
}

function getPlants() {
    return copyJsonObject(pestCategoryBaseObject()["Plants Spiders or Insects"]);
}

function copyJsonObject(jsonObject) {
    return JSON.parse(JSON.stringify(jsonObject));
}

export default refDataObject()["reporterType"];
