// Person data
const initialState = {
    reporterFirstName: "",
    reporterSurname: "",
    reporterPhone: "",
    reporterSecondaryPhone: "", // If there are two phones
    reporterEmail: "",
    reporterRegion: "",

    // lat-lon coords of the address
    location: {},
    locationFoundAtToggle: false,

    // Locations
    locationFoundAt: {},
    locationReporterAddress: {},
};


const person = (state = initialState, action) => {
    // Set the state
    switch (action.type) {

        case 'UPDATE_PERSON_INFO':
            return {
                ...state,
                ...action.person
            };

        case 'SET_LOCATION_FOUND_AT_TOGGLE':
            return {
                ...state,
                locationFoundAtToggle: action.locationFoundAtToggle
            };

        case 'SET_LOCATION':
            return {
                ...state,
                locationFoundAt: action.locationFoundAt
            };

        case 'SET_REPORTER_LOCATION':
            return {
                ...state,
                locationReporterAddress: action.locationReporterAddress,
                reporterRegion: action.locationReporterAddress.countrySecondarySubdivision
            };

        case 'SET_REPORTER_REGION':
            return {
                ...state,
                reporterRegion: action.locationReporterRegion
            };

        case 'CLEAR_PERSON_INFO':
            return initialState;

        default:
            return state
    }
};

export default person;
