export const updateSubmissionID = id => ({
    type: 'UPDATE_SUBMISSION_ID',
    id
});

export const toggleModal = modalFor => ({
    type: 'TOGGLE_MODAL',
    modalFor
});

export const updateSubmitAttempt = () => ({
    type: 'UPDATE_SUBMIT_ATTEMPT',
});


export const toggleMap = () => ({
    type: 'TOGGLE_MAP',
});

export const toggleHeader = () => ({
    type: 'TOGGLE_HEADER',
});

export const resetHeader = () => ({
    type: 'RESET_HEADER',
});

export const updateSubmissionErrors = errors => ({
    type: 'UPDATE_SUBMISSION_ERRORS',
    errors
});