import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone-uploader";

import "./NewFileDropzone.css";
import { updatePestFiles, resetPestFiles } from "../../../../actions/pest";
import { connect, useDispatch } from "react-redux";

const MyUploader = (props) => {
    const dispatch = useDispatch();

    const { updatePestFiles, resetPestFiles } = props;

    // called every time a file's `status` changes
    const handleChangeStatus = ({ meta, file }, status) => {
        let fileArr = [];

        if (status === "done") {
            // Here, convert the file into Base64

            const reader = new FileReader();

            reader.onabort = () => console.log("file reading was aborted");
            reader.onerror = () => console.log("file reading has failed");
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result;
                const fObj = {meta: meta, file: binaryStr};
                // Add the binary string to the array
                fileArr.push(fObj);

                // Update redux state
                dispatch(updatePestFiles(fileArr));
            };

            reader.readAsDataURL(file);
        }
        else if (status === "removed") {
            // Get the pest data
            let files = props.pest.files;

            if (files.length > 0) {              
                var isRemoved=false;

                for(var i = 0; i < files.length; i++) {
                    var fObj=files[i]; //collection is an array of an array which only has 1 file object in it
                    if(fObj[0].meta.name !== file.name || isRemoved){
                        fileArr.push(fObj);
                    }
                   else{
                        isRemoved=true;
                   }
                }

                dispatch(resetPestFiles(fileArr));
            }
        }
    };

    // receives array of files that are done uploading when submit button is clicked
    const handleSubmit = (files, allFiles) => {
        allFiles.forEach((f) => f.remove());
    };

    return (
        <Dropzone
            onChangeStatus={handleChangeStatus}
            accept="image/*"
            inputContent={(files, extra) => (extra.reject ? "Image files only" : "Drag files or click to browse")}
        />
    );
};

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = () => {
    return {
        updatePestFiles,resetPestFiles
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyUploader);
