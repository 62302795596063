import React, { Component, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Autosuggest from "react-autosuggest";

// Styling
import "./AddressSuggest.css";

// Redux
import { setLocationFoundAt } from "../../../../actions/person";
import { toggleMap } from "../../../../actions/form";

const renderSuggestion = (suggestion) => {
    return <div>{suggestion.address.freeformAddress}</div>;
};

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion, props, dispatch) => {
    // Update redux in the parent function
    dispatch(
        setLocationFoundAt({ ...suggestion.address, ... suggestion.position })
    );

    dispatch(toggleMap());

    return suggestion.address.freeformAddress;
};

const AddressSuggest = (props) => {
    // For redux
    const dispatch = useDispatch();
    // Set the state
    const [suggestions, setSuggestions] = useState([]);
    const [value, setValue] = useState("");
    //const [address, setAddress] = useState({});

    const inputProps = {
        placeholder: "Search for address",
        value,
        onChange: (event, { newValue }) => setValue(newValue),
    };

    return (
        <div className="ui-widget">
            <Autosuggest
                suggestions={suggestions}
                inputProps={inputProps}
                onSuggestionsFetchRequested={({ value }) => {
                    // fetch stuff because it's a garbage api
                    // yes, it has to be here
                    // no, don't change any of this

                    let geocodeServiceUrlTemplate =
                        `${process.env.REACT_APP_API}address-search/{searchType}/json?typeahead=true&subscription-key={subscription-key}&api-version=1&query={query}&language={language}&lon={lon}&lat={lat}&countrySet={countrySet}&view=Auto`;

                    // this is roughly the center of NZ
                    let center = [174.91667, -41.21667];
                    let countryIso = "NZ";
                    // Change the following to
                    let requestUrl = geocodeServiceUrlTemplate
                        .replace("{query}", encodeURIComponent(value))
                        .replace("{searchType}", "fuzzy")
                        .replace("{subscription-key}", 'ad89f7a97sf7&KEA&SFFr9wa7r9q79e7r9q8jf3y984')
                        //.replace('{subscription-key}', process.env.MAP_KEY)
                        .replace("{language}", "en-US")
                        .replace("{lon}", center[0]) //Use a lat and lon value of the center the map to bias the results to the current map view.
                        .replace("{lat}", center[1])
                        .replace("{countrySet}", countryIso); //A comma seperated string of country codes to limit the suggestions to.

                    // My lord it works!
                    fetch(requestUrl)
                        .then((res) => res.json())
                        .then(
                            (result) => setSuggestions(result.results),
                            // Note: it's important to handle errors here
                            // instead of a catch() block so that we don't swallow
                            // exceptions from actual bugs in components.
                            (error) => {
                                console.log(error);
                            }
                        );
                }}
                onSuggestionsClearRequested={() => setSuggestions([])}
                // This is also the onClick event
                getSuggestionValue={(suggestion) => getSuggestionValue(suggestion, props, dispatch)}
                renderSuggestion={renderSuggestion}
            />
        </div>
    );
};

// Add redux stuff here
const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = () => {
    return {
        // Add to the address
        setLocationFoundAt,
        toggleMap,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressSuggest);
