export const updatePestInfo = pest => ({
    type: 'UPDATE_PEST_INFO',
    pest
});

export const updatePestReporter = reporterType => ({
    type: 'UPDATE_PEST_REPORTER',
    reporterType
});

export const updatePestCategory = pestCategory => ({
    type: 'UPDATE_PEST_CATEGORY',
    pestCategory
});

export const updatePestSubCategory = pestSubCategory => ({
    type: 'UPDATE_PEST_SUB_CATEGORY',
    pestSubCategory
});

export const updateNotificationScreener = notificationScreener => ({
    type: 'UPDATE_NOTIFICATION_SCREENER',
    notificationScreener
});

export const updateRedirect = redirect => ({
    type: 'UPDATE_REDIRECT',
    redirect
});

export const updatePestDate = pestDate => ({
    type: 'UPDATE_PEST_DATE',
    pestDate
});

export const updatePestFiles = files => ({
    type: 'UPDATE_PEST_FILES',
    files
});
export const resetPestFiles = files => ({
    type: 'RESET_PEST_FILES',
    files
});
export const updateCondQuestions = ({condQuestion, truthiness}) => ({
    type: 'UPDATE_COND_QUESTIONS',
    payload: {condQuestion, truthiness}
});

export const updateIsCondQuestion = isCondQuestion => ({
    type: 'UPDATE_IS_COND_QUESTIONS',
    isCondQuestion
});

export const clearPestInfo = () => ({
    type: 'CLEAR_PEST_INFO',
});