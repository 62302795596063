import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";
import {
    updateCondQuestions,
    updateRedirect,
    updateNotificationScreener,
    updateIsCondQuestion
} from "../../../actions/pest";

// We need static questions
import Questions from '../Questions';

// Thunk helpers
const filterValue = (obj, key, value) => obj.filter(v => v[key] === value);
const getCondQuestionResponse = (obj, truthiness) => filterValue(obj,"value", truthiness)[0];

class ConditionalQuestionsSection extends Component {
    getKeyByValue = (object, value) => {
        return Object.keys(object).find(key => object[key] === value);
    };

    getLabelText = () => {
        let condQuestions = this.props.pest.condQuestions;

        // Find the true value from the object
        let key = this.getKeyByValue(condQuestions, true);

        switch(key) {
            case 'isRecentlyImportedGood':
                return 'Is this associated with a recently imported item?';
            case 'isAnimalMortality':
                return 'Has there been an animal mortality?';
            case 'isBeeDisease':
                return 'Is this about a bee disease?';
            case 'isIdentifyingBeeWasp':
                return 'Is this identifying a bee or wasp?';

            default:
                return "defaulto";
            //break;
        }
    };

    handleChange = (event, response) => {
        // Props shortcuts
        let condQuestions = this.props.pest.condQuestions;
        let pestSubCategory = this.props.pest.pestSubCategory;
        const { history } = this.props;

        // Get the only true key from the condQuestions object
        let key = this.getKeyByValue(condQuestions, response);

        // Get the response object
        let responseObj = getCondQuestionResponse(pestSubCategory.condQuestion.response, response.toString());

        // Update the conditional question
        let condQuestion = key;
        let truthiness = response;
        this.props.updateCondQuestions({condQuestion, truthiness});

        // So.  The person who wrote this bless their filthy soul, decided to mutate the state over the forms lifecycle.
        //   As a result of this, we need to hack the state to make sure the response to the CondQuestion remains in the
        //   payload for return to the server and isn't overwritten with null. Or something like that.
        this.props.updateIsCondQuestion(false);

        // Get the screener from the condQuestion response array
        let screener = responseObj.notificationScreener;
        this.props.updateNotificationScreener(screener);

        // Redirects to
        if(responseObj.redirect) {
            // Update redirect
            this.props.updateRedirect(responseObj.redirectTo);
        } else {
            // Redirect to details page
            history.push("/pest/details/");
        }
    };

    render() {
        // It's just easier to read this way
        let isCondQuestion = this.props.pest.isCondQuestion;

        // Find at least one key with the value 'true'
        if (isCondQuestion) {
            return (
                <div className="form-group">
                    <label>{this.getLabelText()}</label>
                    <div>
                        <div className="btn-group">
                            <label className="btn btn-default"
                                   aria-label={"Yes"}
                                   onClick={(e) => this.handleChange(e, true)}>
                                Yes
                            </label>
                            <label className="btn btn-default"
                                   aria-label={"Yes"}
                                   onClick={(e) => this.handleChange(e, false)}>
                                No
                            </label>
                        </div>
                    </div>
                </div>
            );
        }

        // If no keys are 'true'
        return <div />;
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    updateCondQuestions,
    updateRedirect,
    updateNotificationScreener,
    updateIsCondQuestion
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConditionalQuestionsSection))
