import React from 'react';

export const PrivacyModal = () => (
    // <div>
    //     <h1 id="page-title">Privacy Statement</h1>
    //     <p>We collect personal information from you, including information about your:
    //         <ul>
    //             <li>Name</li>
    //             <li>Contact information</li>
    //             <li>Location</li>
    //         </ul>
    //     </p>
    //     <p>MPI or our partners may use personal information you provide for biosecurity responses and management, improving our service, science or research.</p>
    // </div>
    <div>
        <h1 id="page-title">Privacy Statement</h1>
        <br />
        <p>When using the Exotic Pest and Disease Online Notification reporting tool, we collect personal information from you, including information about
            your:</p>
        <ul>
            <li>name</li>
            <li>contact information</li>
            <li>location (of notifier and the risk)</li>
        </ul>
        <p>We collect this information so we can:</p>
        <ul>
            <li>contact you to further the investigation</li>
            <li>report the collective number and geographical spread of suspected exotic pests and diseases.</li>
            <li>report the collective number and geographical spread of notifiers</li>
        </ul>

        <p>Providing your name and contact details is voluntary. But if we can’t contact you, we may not be able to
            investigate the suspected Pest or Disease you have reported any further.</p>
        <p>You have the right to ask for a copy of any personal information we hold about you, and to ask for it to be
            corrected if you think it is wrong. For further information on how we protect your privacy, or for details
            of how you can contact us with any questions or complaints, please refer to our Transparency and Privacy
            Notice at <a target="_blank" rel="noopener noreferrer" href='https://www.mpi.govt.nz/about-this-site/privacy-and-security/'>https://www.mpi.govt.nz/about-this-site/privacy-and-security/</a></p>

    </div>
);