import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import uuid from "react-uuid";

// Sections
import PestInfo from "./PestInfo";
import PersonInfo from "./PersonInfo";

// Redux
import { toggleModal, updateSubmissionID, updateSubmissionErrors, updateSubmitAttempt } from "../../actions/form";

import "./DetailsPage.css";
import Modals from "../../components/Modals/Modals";
import PersonAddressSuggest from "./PersonInfo/components/PersonAddressSuggest";
import KnownAddresses from "./PersonInfo/components/KnownAddresses";
import ReCAPTCHA from "react-google-recaptcha";

import { Snackbar } from "./snackbar";

// Get the details of the person here.

const getURL = () => {
    return process.env.REACT_APP_API;
};

class DetailsPage extends Component {
    state = {
        code: "",
        status: "",
        isSending: false,
    };

    snackbarRef = React.createRef();
    captchaRef = React.createRef();
    captchaToken = "";

    //getCaptcha = (sendToAzure) => {
    //   return <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_KEY} onChange={sendToAzure} ref={this.captchaRef} />;
    //};

    componentDidMount() {
        const { history } = this.props;

        if (Object.keys(this.props.pest.pestCategory).length === 0) {
            history.push("/pest/");
        }

        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js";
        script.async = true;
        document.body.appendChild(script);
    }

    checkIfRequiredFieldsEmpty = (captchaToken) => {
        const { pest, person } = this.props;

        // Checks if these fields are empty or not
        return pest.pestNumber === "" || pest.pestDescription === "" || captchaToken === "" || person.reporterRegion === "";
    };

    // checkIfIncorrectFieldValue = (captchaToken) => {
        // Fix so that the person object is validated once (ie by person info) and validation is not passed to Azure
    //     const { pest, person } = this.props;
    //    const regexPhone = /^0[0-9]*$/;
    //    if(person.reporterPhone!==""){
    //     return !(regexPhone.test(person.reporterPhone));        
    //    }
    //    else{
    //        return false;
    //    }
    // };

    checkIfValid = (captchaToken) => {       
       const submissionForm = this.props.form;
        return !(submissionForm.submissionErrors.length>0);
    };

    _showSnackbarHandler = (captchaToken) => {
        let validationError = [];
       // const regexPhone = /^0[0-9]*$/;
        validationError.push("Validation Errors");
        const { pest, person } = this.props;
        const submissionForm = this.props.form;
        const isLabUser = pest.reporterType.toLowerCase() === "veterinary diagnostic laboratory";

        // if (person.reporterPhone !=="" && !regexPhone.test(person.reporterPhone)) {
        //     validationError.push(<br />);
        //     validationError.push("- phone number is invalid.");
        // }

        if(submissionForm.submissionErrors.length>0){
            submissionForm.submissionErrors.forEach(err => {
                validationError.push(<br />);
                validationError.push("- " + err.errMessage);
            });
        }

        // if (pest.pestDescription === "") {
        //     validationError.push(<br />);
        //     validationError.push("- a description is required.");
        // }
        // if (pest.pestNumber === "") {
        //     validationError.push(<br />);
        //     validationError.push("- a quantity is required.");
        // }
        if (person.reporterRegion === "" || person.reporterRegion === "N/A") {
            validationError.push(<br />);
            if(isLabUser){
            validationError.push("- a facility is required");
            }
            else{
                validationError.push("- a region is required. We use this information to send details of your observation to the closest available Lab");
            }
        }
        
        const checkDate = (pest.pestDate || "");
        let parsedDate = new Date(checkDate);
        if (!pest.pestDate || pest.pestDate === "" || isNaN(parsedDate.getTime())) {
            validationError.push(<br />);
            validationError.push("- a valid date is required");
        }
    

        if (captchaToken === "") {
            validationError.push(<br />);
            validationError.push("- please complete CAPTCHA");
        }

        validationError.push(<br />);
        validationError.push("Please complete fields and resubmit.");
        this.captchaRef.current.reset();
        this.snackbarRef.current.openSnackBar(validationError);
    };

    sendToAzure = (e) => {
        e.preventDefault();


        // YES this is gross.  isSending is used for both the rendering and the logic.
        //  however because setState is asynchronous which means the logic passes through
        //  before the logic can run.  I don't have time to rewrite this rubbish :(
        this.setState({ isSending: true });
        let isSending = true;
        this.props.updateSubmitAttempt();

        const captchaToken = this.captchaRef.current.getValue();

        let isEmpty = this.checkIfRequiredFieldsEmpty(captchaToken);
       // let isInvalid = this.checkIfIncorrectFieldValue(captchaToken);
       let isValid = this.checkIfValid(captchaToken);
        if (isEmpty || !isValid) {
            window.scrollTo(0, 1200);
            this._showSnackbarHandler(captchaToken);
            this.setState({ isSending: false });
            isSending = false;
            return;
        }

       
        
        // Get the person data
        const person = this.props.person;

        // Get the pest data
        const pest = this.props.pest;

        // Hold all the UUIDs here
        const file_uuids = [];

        let form = this;

        // send all files to the server

        function uploadFileToBlobStorage(fileObj, finish) {

            const id = uuid();
            // send the data to the server
            fetch(getURL() + "addblob", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ image: fileObj.file, blobName: id, fileName: fileObj.meta.name, fileType: fileObj.meta.type}),
            })
            .then((response) => {
                window.scrollTo(0, 0);                      

                if (response.status === 200) {
                    file_uuids.push(id);
                    waiting--;
                    finish();
                }
                else{
                    form.setState({ code: response.status, status: response.statusText });                        
                    return response.json();
                }
                
            })
            .then(
                (result) => {
                    if (result && form.state.code !== 200) {                     
                        form.setState({ isSending: false, status: result.Error });
                        isSending = false;
                    }
                },
                (err) => console.log(err)
            )
                .catch((err) => {
                    // Error
                    window.scrollTo(0, 0);
                    
                    form.setState({ isSending: false, status: "Failed to upload the images", code: "500" });
                    isSending = false;
                });

        }

        let files = pest.files;
        let waiting = files.length;
        if (files.length === 0) {
            finish();
        } else {
            // files.forEach(function (fileObj) {
            //     uploadFileToBlobStorage(fileObj, finish); //call finish after each entry
            // });

            for(var i = 0; i < files.length; i++) {
                var fObj=files[i]; //collection is an array of an array which only has 1 file object in it
                uploadFileToBlobStorage(fObj[0], finish); //call finish after each entry
            }
        }

        function finish() {

            // Check for each file and also if we're still sending images
            if (waiting <= 0 && isSending) {
                //do your Job intended to be done after forEach is completed

                // When there are no more files to add, add the uuids in place of the files
                pest.files = file_uuids;

                if (pest.files.length > 0) {
                    pest.hasImages = 'Yes';
                } else {
                    pest.hasImages = 'No';
                }

                // No tags thanks....
                let data = JSON.stringify({ person: person, pest: pest, captcha: captchaToken });
                // console.log(data);
                // data = data.replace(new RegExp("<", "g"), ' less than ')
                // data = data.replace(new RegExp(">", "g"), ' greater than ')
                // console.log(data);

                fetch(getURL() + "upload", {
                    method: "POST",
                    // This is required content header for success.  without it things are sad.
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: data,
                })
                    .then((response) => {
                        window.scrollTo(0, 0);                      
                        form.setState({ code: response.status, status: response.statusText });                        
                        return response.json();
                        
                    })
                    .then(
                        (result) => {
                            // Get the history from the props
                            const { history } = form.props;

                            if(form.state.code==400 || form.state.code==500){
                                console.log(result);
                                form.setState({ status: result.Error });
                            }
                            else{
                            // Update the form
                            form.props.updateSubmissionID(result.id);

                            // Push the state
                            history.push("/pest/success/");
                            }
                        },
                        (err) => console.log(err)
                    )
                    .catch((err) => {
                        console.log(err);                        
                    });
                isSending = false;
                form.setState({ isSending: false });
            }
        }
    };

    render() {
        let reason = "";
        const isLabUser = this.props.pest.reporterType.toLowerCase() === "veterinary diagnostic laboratory";

        // Make sure that the reason the reporter is reporting stuff isn't null.
        // This stops weirdness happening with refreshes.
        if (Object.keys(this.props.pest.pestCategory).length !== 0) {
            reason = this.props.pest.pestCategory.name.toLowerCase() || "";
        }

        return (
            <div>
                {this.state.status !== "" && this.state.code !== "" && (
                    <div className="alert alert-danger ng-scope ng-isolate-scope alert-dismissible" role="alert">
                        <button type="button" className="close" aria-label={"Close"}>
                            <span aria-hidden="true" onClick={() => this.setState({ status: "", code: "" })}>
                                ×
                            </span>
                            <span className="sr-only">Close</span>
                        </button>
                        <div>
                            <p className="ng-binding ng-scope">
                                There has been an issue with submitting your report to MPI. Please call our support team
                                on 0800 80 99 66 and tell them you are using the Pest Online tool with the status and
                                code.&nbsp; (Status: {this.state.status}. Code: {this.state.code})
                            </p>
                        </div>
                    </div>
                )}

                <Modals />

                <form
                    name="notificationDetailsForm"
                    id="notificationDetailsForm"
                    onSubmit={(e) => {
                        e.preventDefault();
                    }}
                >
                    <p>
                        Thanks for that. You're reporting {reason}. If that is incorrect please{" "}
                        <a href="/pest/">change your answers</a>
                    </p>

                    <p>
                        Biosecurity New Zealand will only use the contact information you provide to contact you about
                        this report or about any feedback you may provide us.
                        <br />
                        <a
                            href=""
                            onClick={(e) => {
                                e.preventDefault();
                                this.props.toggleModal("Privacy");
                            }}
                            aria-label={""}
                        >
                            Read Biosecurity New Zealand's Privacy Statement.
                        </a>
                    </p>

                    <br />
                    <br />

                    <h2>About you</h2>

                    <p>
                        Providing your name and contact details is voluntary. However, to follow up we need your contact
                        details.
                    </p>

                    <br />

                    {/* Details information */}
                    {!isLabUser  && (
                         <>
                                        <PersonInfo />
                                        <PersonAddressSuggest />
                        </>
                    )}
                    {isLabUser && (
                         <>
                                        <PersonInfo />
                                        <KnownAddresses />
                        </>
                    )}
                    <PestInfo />

                    {/* End form */}

                    <div className="Actions form-group" style={{ textAlign: "left" }}>
                        <p style={{ width: "100%" }}>
                            By submitting this form you agree to&nbsp;
                            <a style={{display: "table-cell"}} href = "https://www.mpi.govt.nz/about-this-site/privacy-and-security/" target = "_blank"
                               rel = "noopener noreferrer">MPI's privacy terms and conditions.</a>
                            <br />
                            <br />
                        </p>

                        <div style={{ width: "100%", paddingBottom: "30px" }}>
                            <div>
                                <ReCAPTCHA sitekey={process.env.REACT_APP_CAPTCHA_KEY} ref={this.captchaRef} />
                            </div>
                        </div>

                        {this.state.isSending && (
                            <div className="spinner-border" style={{ marginRight: "15px" }}>
                                <span className="sr-only">Loading...</span>
                            </div>
                        )}
                        <Snackbar ref={this.snackbarRef} />
                        <div style={{ width: "100%" }}>
                            <button
                                className="btn btn-primary"
                                id="submit"
                                name="submit"
                                type="submit"
                                disabled={this.state.isSending}
                                onClick={this.sendToAzure}
                            >
                                Submit your report
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = { updateSubmissionID, toggleModal, updateSubmissionErrors, updateSubmitAttempt };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DetailsPage));
