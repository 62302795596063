import React from "react";
import { connect } from "react-redux";

const h2Css = {
    fontSize: "2.2rem",
    fontWeight: "500",
    color: "#133d5f",
};

const WaterPest = () => (
    <div>
        <div>
            <p>
                <h2 style={h2Css}>Fresh water plants or fresh water algae</h2>
                Any plant or algae (for example didymo) living in fresh water.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>Aquaculture</h2>
                Anything farmed in fresh or salt water.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>Fresh water fish or crustaceans</h2>
                Any animal living in fresh water.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>Marine fish, animals, or seafood</h2>
                Any animal living in salt water.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>Seaweed or marine algae</h2>
                Seaweeds or other salt water plants or algae.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>Illegal fishing or poaching</h2>
                Any fishing activity which might be illegal or is suspicious.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>Whales, seals, or marine mammals</h2>
                For example all whales, dolphins, seals, sea lions.
            </p>
        </div>
    </div>
);

const PlantPest = () => (
    <div>
        <div>
            <p>
                <h2 style={h2Css}>Land plants, fresh water plants or algae</h2>
                Any plant or algae (for example didymo) not growing in the sea.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>Bugs, spiders, or invertebrates</h2>
                Any sort of bug, for example insects or worm, found on land.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>Seaweed or marine algae</h2>
                Seaweeds or other salt water plants or algae.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>A recently imported good</h2>
                Something recently imported which might be hiding a biosecurity risk, for example something just arrived
                from overseas which shows signs of insect damage.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>Ticks, fleas, or animal parasites</h2>
                Seaweeds or other salt water plants or algae.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>Bees</h2>
                Honey bees or any type of wasp.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>Mosquitos</h2>
                Any report of a mosquito, whether it is common to New Zealand or not.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>A plant disease</h2>
                Any disease affecting a plant.
            </p>
        </div>
    </div>
);

const AnimalPest = () => (
    <div>
        <div>
            <p>
                <h2 style={h2Css}>Farm animals</h2>
                An animal being farmed commercially.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>Exotic animals</h2>
                Any animal which isn’t a pet or a farmed animal.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>Domestic animals</h2>
                Pets or companion animals which are not being commercially farmed, for example cats, dogs, birds, and
                horses.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>Ticks, fleas, or animals</h2>
                Any bug or worm which appears to be living on an animal.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>A recently imported good</h2>
                Something recently imported which might be hiding a biosecurity risk, for example something just arrived
                from overseas which shows signs of insect damage.
            </p>
        </div>

        <div>
            <p>
                <h2 style={h2Css}>Bees</h2>
                Honey bees, or diseases affecting bees.
            </p>
        </div>
    </div>
);

//NB: go back to the old page and get the text for the third ?

const PestSubCategoryModal = (props) => {
    switch (props.pest.pestCategory.value) {
        case "Marine or Fresh Water Issue":
            return <WaterPest />;
        case "Plants Spiders or Insects":
            return <PlantPest />;
        case "Animals or Animal Products":
            return <AnimalPest />;

        default:
            return <div />;
    }
};

const mapStateToProps = (state) => {
    return state;
};

export default connect(mapStateToProps)(PestSubCategoryModal);
