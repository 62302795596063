// Describe the initial state of the form
const initialState = {
    // For the pest
    // pest: {
    //     redirect: "",
    //     isCondQuestion: false,
    // },

    submissionID: "",

    // Place to put the modals up
    isModalOpen: false,
    modalFor: "",

    // Map
    showMap: false,

    // This stuff won't be POSTed
    redirect: "",
    isCondQuestion: false,
    isFisheries: false,
    attemptsToSubmit: 0,
    submissionErrors: []
};

const form = (state = initialState, action) => {
    //TODO: move all form related items here

    switch (action.type) {
        case 'UPDATE_SUBMISSION_ID':
            return {
                ...state,
                submissionErrors: [],
                submissionID: action.id
            };
            case 'UPDATE_SUBMISSION_ERRORS':
                return {
                    ...state,
                    submissionErrors: action.errors
                };
                case 'UPDATE_SUBMIT_ATTEMPT':
                    return {
                        ...state,
                        attemptsToSubmit: state.attemptsToSubmit+1
                    };
        case 'TOGGLE_MODAL':
            return {
                ...state,
                isModalOpen: !state.isModalOpen,
                modalFor: action.modalFor
            };

        case 'TOGGLE_MAP':
            return {
                ...state,
                showMap: !state.showMap
            };

        case 'TOGGLE_HEADER':
            return {
                ...state,
                isFisheries: true
            };

        case 'RESET_HEADER':
            return {
                ...state,
                isFisheries: false
            };

        default: return state;
    }
};

export default form;