import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { clearPestInfo } from "../../actions/pest";
import { clearPersonInfo } from "../../actions/person";

class SuccessPage extends Component {
    render() {
        return (
            <div>
                <div>
                    <p>
                        Thank you for your notification.
                        <br />
                        We appreciate the information you provide.
                    </p>

                    <p>Your notification number is {this.props.form.submissionID}</p>

                    <p>
                        <strong>What&apos;s next?</strong>
                    </p>
                    <div className="row">
                        <div className="col-md-3 text-center">
                            <span className="fa fa-envelope-o fa-4x" aria-hidden="true"></span>
                            <br />
                            You will receive an email with your notification number
                            <br />
                            <br />
                        </div>
                        <div className="col-md-3 text-center">
                            <span className="fa fa-user fa-4x" aria-hidden="true"></span>
                            <br />
                            A specialist will look at the information you have provided
                            <br />
                            <br />
                        </div>
                        <div className="col-md-3 text-center">
                            <span className="fa fa-commenting-o fa-4x" aria-hidden="true"></span>
                            <br />
                            The specialist will contact you with an update within two business days
                            <br />
                            <br />
                        </div>
                        <div className="col-md-3 text-center">
                            <span className="fa fa-clock-o fa-4x" aria-hidden="true"></span>
                            <br />
                            Waiting more than two business days? Call us on <a href="tel:0800809966">0800 80 99 66</a>
                            <br />
                            <br />
                        </div>
                    </div>

                    <div className="row">
                        <div className="btn-toolbar pull-right">
                            <div className="btn-group">
                                <button
                                    className="btn btn-primary"
                                    id="reportAnotherPest"
                                    name="reportAnotherPest"
                                    type="button"
                                    onClick={() => {
                                        const { history } = this.props;
                                        this.props.clearPestInfo();
                                        this.props.clearPersonInfo();
                                        history.push("/pest/");
                                    }}
                                >
                                    Report another pest
                                </button>
                            </div>
                            <div className="btn-group">
                                <button
                                    className="btn btn-success"
                                    id="mpiHome"
                                    name="mpiHome"
                                    type="button"
                                    onClick={() => {
                                        //const { history } = this.props;
                                        this.props.clearPestInfo();
                                        this.props.clearPersonInfo();
                                        window.location.href = "http://www.mpi.govt.nz/";
                                        //history.push("http://www.mpi.govt.nz/");
                                    }}
                                >
                                    MPI Home
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = { clearPestInfo, clearPersonInfo };

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SuccessPage));
