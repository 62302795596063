import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Switch, Route, Redirect
} from "react-router-dom";

// pages
import PestPage from "./pages/PestPage";
import DetailsPage from "./pages/DetailsPage";
import RedirectPage from "./pages/RedirectPage";

// Components
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import SuccessPage from "./pages/SuccessPage/SuccessPage";

const mainContentStyle = {
    paddingTop: "4rem",
    height: "100%",

};

const modalOpenStyle = {
    background: "rgba(0, 0, 0, 0.5)",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
}

export default function App() {
    return (
        <Router>
            <Header />
            <main id="main-content" role="main" style={mainContentStyle}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-1" />

                        <div className="col-sm-10">
                            <Switch>
                                <Route exact path="/">
                                    <Redirect to="/pest/" />
                                </Route>

                                {/*  */}
                                <Route exact path="/pest/">
                                    <PestPage />
                                </Route>

                                <Route exact path="/pest/details/">
                                    <DetailsPage />
                                </Route>

                                <Route exact path="/pest/redirect/">
                                    <RedirectPage />
                                </Route>

                                <Route exact path="/pest/success/">
                                    <SuccessPage />
                                </Route>

                                <Route component={() => <Redirect to={"/pest/"} />} />

                            </Switch>
                        </div>

                        <div className="col-sm-1" />
                    </div>
                </div>
            </main>

            <Footer />
        </Router>
    );
};
