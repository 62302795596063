import React, { Component } from "react";
import { connect } from "react-redux";

import { updatePestReporter } from "../../../actions/pest";
import { toggleModal } from "../../../actions/form";

// We need static questions
import Questions from "../Questions";

class ReporterTypeSection extends Component {
    getQuestions = () => {
        let options = [];
        let count = 0;

        // Get the value from the questions
        for (let [key, value] of Object.entries(Questions)) {
            if (count === 0) {
                options.push(
                    <option disabled hidden key={key} value={key} aria-label={value.name}>
                        {value.name}
                    </option>
                );
            } else {
                options.push(
                    <option key={key} value={key} aria-label={value.name}>
                        {value.name}
                    </option>
                );
            }
            count++;
        }

        return options;
    };

    handleChange = (event) => {
        // Update the redux state to have a reporter type
        this.props.updatePestReporter(event.target.value);
    };

    render() {
        return (
            <div className="form-group">
                <label htmlFor="reporterType">I'm a</label>
                <div className="input-group">
                    <select
                        className="form-control"
                        required=""
                        name="reporterType"
                        id="reporterType"
                        aria-label={"I am a"}
                        onChange={this.handleChange}
                        defaultValue=""
                    >
                        {this.getQuestions()}
                    </select>
                    <div
                        className="input-group-addon"
                        onClick={(e) => {
                            e.preventDefault();
                            this.props.toggleModal("ReporterTypeSection");
                        }}
                        aria-label={"Information popup for the reporter type"}
                    >
                        <a href="" aria-haspopup="true">
                            <span className="glyphicon glyphicon-question-sign" style={{ fontSize: "120%" }} />
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = {
    updatePestReporter,
    toggleModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReporterTypeSection);
