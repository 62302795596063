import { combineReducers } from 'redux';

// Reducers
import pest from "./pest";
import person from "./person";
import form from "./form";

// Data we need:
// Pest data
// Person data
// Redirect?

export default combineReducers({
    pest,
    person,
    form
});
