import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import Autosuggest from "react-autosuggest";

// Redux
import { toggleMap } from "../../../../actions/form";
import {setReporterAddress, setReporterRegion} from "../../../../actions/person";

const getSuggestionValue = (suggestion, props, dispatch) => {
    // Update the secondary location information

    dispatch(
        setReporterAddress({ ...suggestion.address, ... suggestion.position })
    );

    return suggestion.address.freeformAddress;
};

const getDataOrEmptyString = (str) => {
    if (str === undefined) return "";
    return str.length > 0 ? str : "";
};

const renderSuggestion = (suggestion) => {
    return <div>{suggestion.address.freeformAddress}</div>;
};

const PersonAddressSuggest = (props) => {
    // For redux
    const dispatch = useDispatch();
    // Set the state
    const [suggestions, setSuggestions] = useState([]);
    const [isValidAddress, setIsValidAddress] = useState(true); // True for starters, set to false after first blur if not valid

    // Use this to check if the address is selected or not
    const [isSelectedAddress, setIsSelectedAddress] = useState(true);

    // Different values for the dropdown
    const [addressValue, setAddressValue] = useState("");
    const [suburbValue, setSuburbValue] = useState("");
    const [townValue, setTownValue] = useState("");
    const [postcodeValue, setPostcodeValue] = useState("");
    const [regionValue, setRegionValue] = useState("");

    const inputProps = (type) => {
        switch (type) {
            case "suburb":
                return {
                    placeholder: "Suburb",
                    value: suburbValue,
                    onChange: (event, { newValue }) => setSuburbValue(getDataOrEmptyString(newValue)),
                    onBlur: onBlur,
                };
            case "town":
                return {
                    placeholder: "Town/City",
                    value: townValue,
                    onChange: (event, { newValue }) => setTownValue(getDataOrEmptyString(newValue)),
                    onBlur: onBlur,
                };
            case "postcode":
                return {
                    placeholder: "Postcode",
                    value: postcodeValue,
                    onChange: (event, { newValue }) => setPostcodeValue(getDataOrEmptyString(newValue)),
                    onBlur: onBlur,
                };
            default:
                break;
        }

        return {
            placeholder: "Search for address",
            value: addressValue,
            onChange: (event, { newValue }) => setAddressValue(newValue),
            onBlur: onBlur,
        };
    };

    const onSuggestionsFetchRequested = (value) => {
        // URL to post
        let geocodeServiceUrlTemplate =
            `${process.env.REACT_APP_API}address-search/{searchType}/json?typeahead=true&subscription-key={subscription-key}&api-version=1&query={query}&language={language}&lon={lon}&lat={lat}&countrySet={countrySet}&view=Auto`;

        // Get nearish to the center to bias the results
        let center = [174.91667, -41.21667];
        // Also, try and get the NZ results only
        let countryIso = "NZ";

        let requestUrl = geocodeServiceUrlTemplate
            .replace("{query}", encodeURIComponent(value))
            //.replace('{searchType}', "fuzzy")
            .replace("{searchType}", "address")
            .replace("{subscription-key}", 'ad89f7a97sf7&KEA&SFFr9wa7r9q79e7r9q8jf3y984')
            .replace("{language}", "en-US")
            .replace("{lon}", center[0])
            .replace("{lat}", center[1])
            .replace("{countrySet}", countryIso);

        // My lord it works!
        fetch(requestUrl)
            .then((res) => res.json())
            .then(
                (result) => setSuggestions(result.results),
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    console.log(error);
                }
            );
    };

    const onSuggestSelected = (event, { suggestion }) => {
        const streetAddress = suggestion.address.freeformAddress.split(",")[0];

        const { municipalitySubdivision, municipality, postalCode, countrySecondarySubdivision } = suggestion.address;

        setAddressValue(getDataOrEmptyString(streetAddress));
        if (municipalitySubdivision) {
            setSuburbValue(municipalitySubdivision);
        }
        setTownValue(getDataOrEmptyString(municipality));
        setPostcodeValue(getDataOrEmptyString(postalCode));
        setRegionValue(getDataOrEmptyString(countrySecondarySubdivision));
    };

    const onBlur = () => {
        // Here, check if the address exists or not

        setIsValidAddress(false);
    };

    return (
        <>
            <div className="form-group">
                <label htmlFor="reporterStreet" aria-label={"Your Address"}>
                    Your address
                </label>
                <Autosuggest
                    suggestions={suggestions}
                    inputProps={inputProps("address")}
                    onSuggestionsFetchRequested={({ value }) => onSuggestionsFetchRequested(value)}
                    onSuggestionSelected={(event, { suggestion }) => onSuggestSelected(event, { suggestion })}
                    onSuggestionsClearRequested={() => setSuggestions([])}
                    // This is also the onClick event
                    getSuggestionValue={(suggestion) => {
                        return getSuggestionValue(suggestion, props, dispatch);
                    }}
                    renderSuggestion={renderSuggestion}
                    aria-label={"Address box"}
                />
            </div>
            <div className="form-group">
                <label htmlFor="reporterStreet" aria-label={"Suburb"}>
                    Suburb/RD
                </label>
                <Autosuggest
                    suggestions={suggestions}
                    inputProps={inputProps("suburb")}
                    onSuggestionsFetchRequested={({ value }) => onSuggestionsFetchRequested(value)}
                    onSuggestionSelected={(event, { suggestion }) => onSuggestSelected(event, { suggestion })}
                    onSuggestionsClearRequested={() => setSuggestions([])}
                    // This is also the onClick event
                    getSuggestionValue={(suggestion) => {
                        return getSuggestionValue(suggestion, props, dispatch);
                    }}
                    renderSuggestion={renderSuggestion}
                    aria-label={"Suburb box"}
                />
            </div>
            <div className="form-group">
                <label htmlFor="reporterStreet" aria-label={"Town box"}>
                    Town/City
                </label>
                <Autosuggest
                    suggestions={suggestions}
                    inputProps={inputProps("town")}
                    onSuggestionsFetchRequested={({ value }) => onSuggestionsFetchRequested(value)}
                    onSuggestionSelected={(event, { suggestion }) => onSuggestSelected(event, { suggestion })}
                    onSuggestionsClearRequested={() => setSuggestions([])}
                    // This is also the onClick event
                    getSuggestionValue={(suggestion) => {
                        return getSuggestionValue(suggestion, props, dispatch);
                    }}
                    renderSuggestion={renderSuggestion}
                    aria-label={"Town/City box"}
                />
            </div>
            <div className="form-group">
                <label htmlFor="reporterStreet" aria-label={"Postcode"}>
                    Postcode
                </label>
                <Autosuggest
                    className="form-control"
                    suggestions={suggestions}
                    inputProps={inputProps("postcode")}
                    onSuggestionsFetchRequested={({ value }) => onSuggestionsFetchRequested(value)}
                    onSuggestionSelected={(event, { suggestion }) => onSuggestSelected(event, { suggestion })}
                    onSuggestionsClearRequested={() => setSuggestions([])}
                    // This is also the onClick event
                    getSuggestionValue={(suggestion) => getSuggestionValue(suggestion, props, dispatch)}
                    renderSuggestion={renderSuggestion}
                    aria-label={"Postcode box"}
                />
            </div>
            <div className="form-group">
                <label htmlFor="pestRegion">
                    Region you live in <span className="req-indicator">(required)</span>
                </label>
                <select
                    className="form-control"
                    name="pestRegion"
                    id="pestRegion"
                    required
                    value={regionValue}
                    onChange={(event) =>
                    {
                        let value = event.target.value;
                        setRegionValue(value)
                        dispatch(
                            setReporterRegion(value)
                        );
                    }}
                >
                    <option>N/A</option>
                    <option aria-label="Northland" value="Northland">
                        Northland
                    </option>
                    <option aria-label="Auckland" value="Auckland">
                        Auckland
                    </option>
                    <option aria-label="Waikato" value="Waikato">
                        Waikato
                    </option>
                    <option aria-label="Bay of Plenty" value="Bay of Plenty">
                        Bay of Plenty
                    </option>
                    <option aria-label="Gisborne" value="Gisborne">
                        Gisborne
                    </option>
                    <option aria-label="Hawke's Bay" value="Hawke's Bay">
                        Hawke&apos;s Bay
                    </option>
                    <option aria-label="Taranaki" value="Taranaki">
                        Taranaki
                    </option>
                    <option aria-label="Manawatu" value="Manawatu">
                        Manawatu-Wanganui
                    </option>
                    <option aria-label="Wellington" value="Wellington">
                        Wellington
                    </option>
                    <option aria-label="Marlborough" value="Marlborough">
                        Marlborough
                    </option>
                    <option aria-label="Nelson" value="Nelson">
                        Nelson
                    </option>
                    <option aria-label="Tasman" value="Tasman">
                        Tasman
                    </option>
                    <option aria-label="West Coast" value="West Coast">
                        West Coast
                    </option>
                    <option aria-label="Canterbury" value="Canterbury">
                        Canterbury
                    </option>
                    <option aria-label="Otago" value="Otago">
                        Otago
                    </option>
                    <option aria-label="Southland" value="Southland">
                        Southland
                    </option>
                </select>
            </div>
        </>
    );
};
// Add redux stuff here
const mapStateToProps = (state) => {
    return state;
};

const mapDispatchToProps = () => {
    return {
        // Add to the address and location
        setReporterAddress,
        toggleMap,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PersonAddressSuggest);
