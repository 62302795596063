function refAddressObject() {
    var refData = {       
        "Gribbles Veterinary": {
            name: "Gribbles Veterinary",
            labRegion: {
                "Auckland": {
                    address:"37-41 Carbine Road",
                    suburb:"Mount Wellington",
                    city:"Auckland",
                    postcode:"1060",
                    region:"Auckland",
                    countrySubdivision: "North Island",
                    countryCode: "NZ",
                    country: "New Zealand",
                    countryCodeISO3: "NZL"
                },
                "Waikato": {
                    address:"5 Karewa Place",
                    suburb:"Hamilton",
                    city:"Hamilton",
                    postcode:"3200",
                    region:"Waikato",
                    countrySubdivision: "North Island",
                    countryCode: "NZ",
                    country: "New Zealand",
                    countryCodeISO3: "NZL",
                },
                "Manawatu": {
                    address:"840 Tremaine Avenue",
                    suburb:"",
                    city:"Palmerston North",
                    postcode:"4414",
                    region:"Manawatu",
                    countrySubdivision: "North Island",
                    countryCode: "NZ",
                    country: "New Zealand",
                    countryCodeISO3: "NZL"
                },
                "Canterbury": {
                    address:"7 Halkett Street",
                    suburb:"",
                    city:"Christchurch",
                    postcode:"",
                    region:"Canterbury",
                    countrySubdivision: "South Island",
                    countryCode: "NZ",
                    country: "New Zealand",
                    countryCodeISO3: "NZL",
                },
                "Otago": {
                    address:"Invermay Research Centre (Block A), Puddle Alley",
                    suburb:"Mosgiel",
                    city:"Mosgiel",
                    postcode:"9092",
                    region:"Otago",
                    countrySubdivision: "South Island",
                    countryCode: "NZ",
                    country: "New Zealand",
                    countryCodeISO3: "NZL",
                }
            }
        },
        "IDEXX Laboratories": {
            name: "IDEXX Laboratories",
            labRegion: {
                "Waikato": {
                    address:"20A Maui Street",
                    suburb:"Pukete",
                    city:"Hamilton",
                    postcode:"3200",
                    region:"Waikato",
                    countrySubdivision: "North Island",
                    countryCode: "NZ",
                    country: "New Zealand",
                    countryCodeISO3: "NZL",
                },
                "Manawatu": {
                    address:"SOVS Complex, Masssey University",
                    suburb:"Tennent Drive",
                    city:"Palmerston North",
                    postcode:"4472",
                    region:"Manawatu",
                    countrySubdivision: "North Island",
                    countryCode: "NZ",
                    country: "New Zealand",
                    countryCodeISO3: "NZL"
                } 
            }          
        },
        "Massey University": {
            name: "Massey University",
            labRegion: {
                "Manawatu": {
                    address:"Tennent Drive",
                    suburb:"",
                    city:"Palmerston North",
                    postcode:"4472",
                    region:"Manawatu",
                    countrySubdivision: "North Island",
                    countryCode: "NZ",
                    country: "New Zealand",
                    countryCodeISO3: "NZL"
                }
            }
        },
        "MPI Animal Health Laboratory": {
            name: "MPI Animal Health Laboratory",
            labRegion: {
                "Wellington": {
                    address:"66 Ward Street",
                    suburb:"Wallaceville",
                    city:"Upper Hutt",
                    postcode:"5018",
                    region:"Wellington",
                    countrySubdivision: "",
                    countryCode: "NZ",
                    country: "New Zealand",
                    countryCodeISO3: "NZL"
                }
            }
        },
        "SVS Laboratories": {
            name: "SVS Laboratories",
            labRegion: {
                "Waikato": {
                    address:"524 Te Rapa Road",
                    suburb:"",
                    city:"Hamilton",
                    postcode:"3200",
                    region:"Waikato",
                    countrySubdivision: "North Island",
                    countryCode: "NZ",
                    country: "New Zealand",
                    countryCodeISO3: "NZL"
                }
            }
        }

    }
    return refData;
}

export default refAddressObject();