import React, { Component } from 'react';

export default class Header extends Component {
    render() {
        return (
            <footer id="page-foot" role="contentinfo">
                <div id="page-foot-upper">
                    <div className="wrapper table">

                        <section id="footer-nav" className="footer__sections" role="contentinfo"
                                 aria-label="Site Section Links">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12">
                                        <div className="container-fluid container--noPadding">

                                            <div className="row">
                                                <ul className="footer-sitelinks">

                                                    <li><a href="https://www.mpi.govt.nz/about-mpi/" rel="nofollow">About
                                                        MPI</a></li>

                                                    <li><a
                                                        href="https://www.mpi.govt.nz/covid-19-information-and-advice/"
                                                        rel="nofollow">COVID-19 information and advice</a></li>

                                                    <li><a href="https://www.mpi.govt.nz/consultations/?opened=1"
                                                           rel="nofollow">Consultations</a></li>

                                                    <li><a href="https://www.mpi.govt.nz/news/" rel="nofollow">News</a>
                                                    </li>

                                                    <li><a href="https://www.mpi.govt.nz/science/"
                                                           rel="nofollow">Science</a></li>

                                                    <li><a href="https://www.mpi.govt.nz/legal/"
                                                           rel="nofollow">Legal</a></li>

                                                    <li><a href="https://www.mpi.govt.nz/resources-and-forms/"
                                                           rel="nofollow">Resources and forms</a></li>

                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>


                        <section className="table-cell">
                            <h2>Updates</h2>
                            <ul>
                                <li><a target="_blank" rel="noopener noreferrer"
                                       href="http://www.mpi.govt.nz/news-and-resources/subscribe-to-mpi/">Subscribe to
                                    MPI</a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href="http://www.mpi.govt.nz/news-and-resources/mpi-rss-feeds/">Subscribe
                                    to RSS feeds</a></li>
                            </ul>
                            <hr role='presentation' />

                            <h2>Follow us</h2>
                            <ul className="follow-link-list">
                                <li><a href="https://twitter.com/MPI_NZ" target="_blank" rel="noopener noreferrer"
                                       title="MPI Twitter page."><span className="fa-social"></span></a></li>
                                <li>
                                    <a href="http://www.youtube.com/minprimaryindustries" target="_blank" rel="noopener noreferrer"
                                       title="MPI YouTube page."><span className="fa-social"></span></a>
                                </li>
                                <li><a
                                    href="http://www.linkedin.com/company/new-zealand-ministry-for-primary-industries"
                                    target="_blank" rel="noopener noreferrer"
                                    title="MPI LinkedIn page."><span className="fa-social"></span></a></li>
                                <li>
                                    <a href="https://www.facebook.com/MPIgovtnz" target="_blank" rel="noopener noreferrer"
                                       title="MPI Facebook page."><span className="fa-social facebook"></span></a>
                                </li>


                            </ul>
                        </section>

                        <section id="footer-contacts" className="table-cell">
                            <h2>Contacts</h2>
                            <ul>


                                <li><span>General enquiries – NZ only</span><a href="tel:0800008333" rel="nofollow">0800
                                    00 83 33</a></li>

                                <li><span>General enquiries</span><a href="tel:+6448940100" rel="nofollow">+64 4 894
                                    0100</a></li>

                                <li><span>Report exotic pests/diseases </span><a href="tel:0800809966" rel="nofollow">0800
                                    80 99 66</a></li>

                                <li><span>Report illegal fishing activity </span><a href="tel:0800476224"
                                                                                    rel="nofollow">0800 47 62 24</a>
                                </li>

                                <li><span>Food safety helpline </span><a href="tel:0800008333" rel="nofollow">0800 00 83
                                    33</a></li>

                                <li><span>Email</span><a href="mailto:info@mpi.govt.nz"
                                                         rel="nofollow">info@mpi.govt.nz</a></li>


                                <p><span><a title="See more contact details" href="http://www.mpi.govt.nz/contact-us/"
                                            rel="nofollow">See more contact details</a></span></p>

                            </ul>
                        </section>
                    </div>
                </div>

                <div id="page-foot-lower">
                    <div id="page-foot-utility-links" className="wrapper">
                        <p>

                            <a href="http://www.mpi.govt.nz/about-this-site/disclaimer/" rel="nofollow">

                                Disclaimer</a> &nbsp;|&nbsp;

                            <a href="http://www.mpi.govt.nz/about-this-site/mpi-copyright/" rel="nofollow">

                                MPI copyright</a> &nbsp;|&nbsp;

                            <a href="http://mpi.govt.nz/feedback-form/">Feedback</a> &nbsp;|&nbsp;

                            <a ui-sref="privacy" ui-sref-active="active" rel="nofollow">Privacy</a>

                        </p>
                        <p id="nz-govt-footer-link"><a href="http://www.govt.nz/"
                                                       title="newzealand.govt.nz - connecting you to New Zealand central &amp; local government services"
                                                       target="_blank" rel="noopener noreferrer">Govt.nz</a></p>
                    </div>
                </div>
            </footer>
        );
    }
};